import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
// import { Helmet } from "react-helmet";
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { fetchBlogDetails } from '../store/blogSlice';
import { BASE_URL } from '../setting/apiConfig';

const Blog = () => {

    const dispatch = useDispatch();
    const { isLoading, data: blogdetails, error } = useSelector((state) => state.blog);

    useEffect(() => {
        dispatch(fetchBlogDetails());
    }, [dispatch]);

    console.log('blog details', blogdetails);

    return (
        <>
            <div className="application">
                <HelmetProvider>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Blogs | Latest Blogs on Courses, Colleges and Exams - Nayeedisha.in</title>
                    <link rel="canonical" href={`${BASE_URL}blogs`} />
                    <meta property="og:title" content="Blogs | Latest Blogs on Courses, Colleges and Exams - Nayeedisha.in" />
                    <meta property="og:description" content="Blogs | Latest Blogs on Courses, Colleges and Exams - Nayeedisha.in" />
                    <meta property="og:image" content="https://media.nayeedisha.in/blog_thumbnails/MBBS_in_Russia_2025-26_dP57q0l.png" />
                    <meta property="og:type" content={`${BASE_URL}`} />
                </Helmet>
                </HelmetProvider>
            </div>

            {/* section1 */}
            <div className='abt-section-news pt-5 pb-5 text-center'>
                <div className='container'>
                    <h3 className="text-white"> Latest Blogs & Stories</h3>
                    <div className='bds-news'></div>
                    <div id='brdrs'></div>
                </div>
            </div>

            <div className="section5">
                <div className="container pt-5 pb-5">
                    <div className="row">
                        {isLoading ? (
                            // Skeleton loader when data is loading
                            Array.from({ length: 6 }).map((_, index) => (
                                <div className="col-md-4 mt-3" key={index}>
                                    <div className="card" style={{ width: '100%' }}>
                                        <Skeleton height={150} />
                                        <div className="card-body crdr1">
                                            <Skeleton height={20} width="80%" style={{ marginBottom: 6 }} />
                                            <Skeleton height={10} width="60%" style={{ marginBottom: 6 }} />
                                            <Skeleton height={10} width="80%" />
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            // Render blog posts when data is available
                            blogdetails.map((blog) => {
                                // Truncate the content
                                const truncatedContent = blog.content?.split(' ').slice(0, 25).join(' ');
                                // Format the date
                                const createdAtDate = new Date(blog.created_at);
                                const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
                                const day = createdAtDate.getDate();
                                const monthIndex = createdAtDate.getMonth();
                                const year = createdAtDate.getFullYear();
                                const formattedDate = `${day} ${months[monthIndex]} ${year}`;
                                return (
                                    // Each blog post rendering
                                    <div className="col-md-4 mt-3" key={blog.slug}>
                                        <Link to={`/blogs/${blog.slug}`}>
                                            <div className="card crdr1" style={{ width: '100%' }}>
                                                <img src={blog.thumbnail} className="w-100 blog" loading="lazy" alt={blog.title} />
                                                <div className="card-body font-height">
                                                    <div className="row">
                                                        <div className="col-md-12 col-lg-12 col-12">
                                                            <b className="" style={{ textTransform: 'capitalize' }}>
                                                                {/* {blog.title ? blog.title : 'Not found'}  */}
                                                                {blog.title
                                                                    
                                                                    }
                                                            </b>
                                                            <div className="d-flex justify-content-between">
                                                                {/* <p className="py-2">{formattedDate}</p> */}
                                                                {/* <p className="py-2"><GrView />&nbsp;{blog.views}</p> */}
                                                            </div>
                                                            {/* <div className="content-text" dangerouslySetInnerHTML={{ __html: truncatedContent || '' }} /> */}
                                                            {/* <Link to={`/blogs/${blog.slug}`}> View More</Link> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                );
                            })
                        )}
                    </div>
                </div>
            </div>
            <br />
        </>
    );
};

export default Blog;
