import { React, useEffect, useState } from "react";
import { useParams } from "react-router-dom"; 
// import { Helmet } from "react-helmet";
import { HelmetProvider, Helmet } from 'react-helmet-async';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { fetchUniversityDetails, clearUniversityDetails } from '../../store/universityDetailsSlice'; // Adjust path
import { useDispatch, useSelector } from 'react-redux';
import Skeleton from "react-loading-skeleton";
import Callbackrequest from '../../callback/index';
import { BASE_URL } from '../../setting/apiConfig';


const DetailsPage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { data: university, loading, error } = useSelector((state) => state.universityDetails);

  useEffect(() => {
    dispatch(fetchUniversityDetails(id));

    return () => {
      dispatch(clearUniversityDetails());
    };
  }, [id, dispatch]);


  if (loading) return (
    <div>
        <Skeleton count={5} height={30} />
        <Skeleton count={3} height={50} />
    </div>
);

  if (error) return <div>Error: {error}</div>;
  if (!university) return <div>
  <Skeleton count={5} height={30} />
  <Skeleton count={3} height={50} />
</div>;

const bannerImage = university.banner || `${BASE_URL}static/media/Russia_bnr.561b14c570314f10d2b2.png`;


  return (
    <>
    
<HelmetProvider>
      <Helmet>
        <title>{university.metatitle}</title>
        <meta name="description" content={university.metadescription} />
        <meta name="keywords" content={university.metakeywords} />
        <link rel="canonical" href={`${BASE_URL}${university.slug}`} />
        {/* Open Graph Tags */}
    
        <meta property="og:title" content={university.title} />
        <meta property="og:description" content={university.metadescription} />
        <meta property="og:image" content={university.thumbnail} />
        <meta property="og:url" content={`${BASE_URL}${university.slug}`} />
        <meta property="og:type" content="website" />
        
        {/* Optional Twitter Card Tags */}
        <meta name="twitter:card" content={university.thumbnail} />
        <meta name="twitter:title" content={university.title} />
        <meta name="twitter:description" content={university.metadescription} />
        <meta name="twitter:image" content={university.thumbnail} />
      </Helmet>
    </HelmetProvider>
     
      <div 
      className='abt-section_russia abt-section pt-5 pb-5' 
      style={{ backgroundImage: `url(${bannerImage})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
    >
        <div className='container'>
          <h1 className="h3 text-light">{`${university.title}`}</h1>
          <Breadcrumb>
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item className="text-white" active>
             {university.title}
            </Breadcrumb.Item>
          </Breadcrumb>
          <div className='bds'></div>
          <div id='brdrs'></div>
        </div>
      </div>

      

      <div className='container abt-2 mt-5 mb-5'>
        {/* Display the content if available */}
        {university.content ? (
          <div dangerouslySetInnerHTML={{ __html: university.content }}  />
        ) : (
     
          <div>No content available.</div>
        )}
        <Callbackrequest />
      </div>
    </>
  );
};

export default DetailsPage;
