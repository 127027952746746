import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {API_URL} from '../setting/apiConfig'
// Create Async Thunk to fetch blog details
export const fetchBlogDetails = createAsyncThunk(`${API_URL}blog-details`, async () => {
    const response = await fetch(`${API_URL}blog-details`);
    if (!response.ok) {
        throw new Error('Failed to fetch blog details');
    }
    const data = await response.json();
    return data;
});

const initialState = {
    isLoading: false,
    data: [],
    error: null,
};

const blogSlice = createSlice({
    name: 'blog',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchBlogDetails.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchBlogDetails.fulfilled, (state, action) => {
                state.isLoading = false;
                state.data = action.payload; // Set data from API response
            })
            .addCase(fetchBlogDetails.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message;
            });
    },
});

export default blogSlice.reducer;
