import React from 'react';
import aboutusbanner from '../img/aboutusbanner.png';
// import { Helmet } from "react-helmet";
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { BASE_URL } from '../../setting/apiConfig';




const Aboutus = () => {
    return (
        <>

            <div className="application">
                <HelmetProvider>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>About | Get The Best and Most Accurate Counseling for The MBBS Aboard NayeeDisha.in </title>
                    <meta name="description" content="TDiscovering the best MBBS course in Aboard? Just, contact with Nayee Disha Counsellors and get the perfect guidance and mentors for the university selection, etc. " />
               <meta name="keywords"
                           content="Discovering the best MBBS course in Aboard? Just, contact with Nayee Disha Counsellors and get the perfect guidance and mentors for the university selection, etc. " />
              
                    <link rel="canonical" href={`${BASE_URL}about-us`} />
                </Helmet>
                </HelmetProvider>
            </div>
            {/* section1 */}

            <div className='weprovide-section-news pt-5 pb-5'>


               <div className='container'>
                    <h3 className="text-white fw-700 h2"> About Us</h3>
                    <div className='bds-news'></div>
                    <div id='brdrs'></div>
                </div>


            </div>




            <div className='container abt-2 mt-5 mb-5'>


                <p className='mb-4'><span className='at_edu'>A</span>t Nayee Disha, we understand the significance of quality education and the transformative impact it can have on an individual’s life. With this understanding, we strive to provide comprehensive guidance and expert advice to students, ensuring they make informed decisions that align with their academic aspirations and career goals. Our team of seasoned professionals, well-versed in the intricacies of international education, works diligently to simplify the complex process of studying abroad, offering personalized solutions tailored to each student’s unique needs.</p>


                <hr></hr>


                <h5 className='mt-4'>Nayee Disha Mission</h5>

                <ul className='mb-4'>

                    <li>
                        <b>“At Nayee Disha Counselling Educational Services LLP, our mission is to empower aspiring medical students with the opportunity to pursue their dreams of becoming doctors.</b> We are committed to providing comprehensive guidance and support to students who seek to study MBBS abroad. Our aim is to bridge the gap between ambition and achievement by offering reliable, transparent, and personalized services.</li>
                    <li className='mt-2'><b>We believe that every student deserves access to quality medical education, regardless of geographical boundaries.</b> Through our extensive network of esteemed universities and educational institutions, we strive to connect students with reputable MBBS programs in countries like Russia, Bangladesh, and other nations known for their excellence in medical education.</li>
                    <li className='mt-2'><b>Our dedicated team of experienced consultants works closely with students and their families, offering personalized assistance at every step of the journey.</b> From helping students choose the right university to guiding them through the application process, visa procedures, and even post-arrival support, we are committed to ensuring a smooth and successful transition into their chosen MBBS program.
                    </li>
                    <li className='mt-2'><b>Integrity, transparency, and professionalism are the cornerstones of our services.</b> We prioritize the best interests of our students, ensuring that they make informed decisions that align with their academic goals and personal aspirations. We strive to provide accurate and up-to-date information about various universities, their programs, admission requirements, and associated costs, enabling students to make well-informed choices.</li>
                    <li className='mt-2'><b>At Nayee Disha Counselling Educational Services LLP, we take pride in witnessing the transformation of ambitious individuals into competent medical professionals.</b> By guiding and supporting our students, we contribute to building a brighter future, both for them and for the global healthcare community.
                    </li>


                    <li className='mt-2'><b>Join us on this journey of discovery, growth, and achievement.</b> Together, let’s embark on a new path towards a successful and fulfilling medical career.”
                    </li>

                </ul>



                <hr></hr>




                <h5 className='mt-4'>Nayee Disha Vision</h5>

                <ul className='mb-4'>

                    <li>Empowering students to realize their potential and achieve their dreams of pursuing a medical education.</li>
                    <li className='mt-2'> Becoming a trusted and leading educational consultant in the field of MBBS admissions, known for our expertise and exceptional service.
                    </li>
                    <li className='mt-2'>Expanding our network of partner universities and institutions worldwide to offer students a diverse range of high-quality medical programs.
                    </li>
                    <li className='mt-2'>Providing comprehensive guidance and support to students throughout their academic journey, from university selection to successful graduation. </li>
                    <li className='mt-2'>Fostering global perspectives and cultural exchange among students by facilitating international education opportunities.</li>


                    <li className='mt-2'>  Advocating for accessible and inclusive education, ensuring that deserving students from all backgrounds have equal opportunities to pursue medical studies abroad.</li>
                    <li className='mt-2'>Continuously adapting and evolving to stay abreast of changes in the field of medical education and meet the evolving needs of students.</li>
                    <li className='mt-2'>Building long-term relationships with students and their families, serving as a lifelong resource and support system for their educational and professional endeavors.</li>
                    <li className='mt-2'>Contributing to the advancement of the healthcare industry by nurturing skilled and compassionate medical professionals who can make a positive impact on society.</li>
                    <li className='mt-2'>Making a lasting impact on the lives of students, helping them embark on a rewarding career path that aligns with their passions and aspirations.</li>



                </ul>


                <hr></hr>




                <h5 className='mt-4'>
                    Nayee Disha support Students</h5>

                <p>
                    <b>
                    Nayee Disha Counselling Educational Services LLP is dedicated to providing comprehensive support to students throughout their academic journey. Here’s a brief outline of the student support offered by Nayee Disha Education:</b>

                </p>

                <ul className='mb-4'>

                    <li><b>Personalized Guidance:</b> Nayee Disha Education offers personalized guidance to students, understanding their unique aspirations, academic background, and preferences. Consultants work closely with students to help them make informed decisions regarding university selection, admission requirements, and program choices.</li>
                    <li className='mt-2'> <b>Application Assistance:</b> The team at Nayee Disha Education assists students in preparing and submitting their applications to universities. Consultants provide guidance on the application process, ensure that all necessary documents are in order, and help students present their qualifications and achievements effectively.</li>
                    <li className='mt-2'><b>Visa and Documentation Support:</b> Nayee Disha Education helps students navigate the visa application process, providing guidance on required documentation, interview preparation, and other necessary procedures. Consultants ensure that students have the necessary information and support to obtain the appropriate visas for their chosen study destination.

                    </li>
                    <li className='mt-2'><b> Pre-Departure Guidance:</b> Prior to students’ departure for their chosen study destination, Nayee Disha Education provides comprehensive pre-departure guidance. This includes guidance on travel arrangements, accommodation options, packing essentials, and any necessary preparations to ensure a smooth transition to their new environment.

                    </li>
                    <li className='mt-2'><b>Ongoing Support:</b> Nayee Disha Education believes in providing continuous support to students even after they have commenced their studies. Consultants remain accessible to address any concerns or queries that students may have throughout their academic journey. This support may include assistance with academic challenges, cultural adjustment, and general well-being.</li>





                    <li className='mt-2'><b>  Alumni Network: </b> Nayee Disha Education encourages students to become part of an alumni network, connecting them with other students who have successfully completed their medical education abroad. This network provides an opportunity for students to seek advice, share experiences, and build professional connections within the medical field.</li>

                    <li className='mt-2'><b>Additional Services:</b> In addition to academic support, Nayee Disha Education may offer additional services such as assistance with finding part-time job opportunities, guidance on scholarship options, and access to resources and information related to the students’ chosen study destination.</li>


                </ul>


                <p><b>Overall, Nayee Disha Counselling Educational Services LLP strives to provide comprehensive and holistic support to students, ensuring that they have the necessary guidance and resources to thrive academically and personally throughout their journey of pursuing MBBS programs abroad.</b>

                </p>


                <hr></hr>




                {/* <h5 className='mt-4'>College Reviews</h5>

                <ul className='mb-4'>

                    <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent vitae tortor pretium massa elementum fermentum. Nam fermentum felis ipsum, non tincidunt ex rhoncus et. Donec eu nibh et quam rutrum dignissim. Integer tincidunt ligula</li>
                    <li className='mt-2'> In semper turpis vitae nisi euismod, tempus scelerisque felis mollis. Quisque in magna mattis, dignissim velit quis, mattis turpis. In vehicula libero id massa volutpat, id feugiat odio luctus. Nulla sit amet eros ante. Nullam velit turpis, volutpat id interdum a, vulputate nec turpis.</li>
                    <li className='mt-2'> Aenean dignissim, purus eget egestas lacinia, purus est dignissim purus.</li>



                </ul> */}




            </div>





        </>
    );
};

export default Aboutus;
