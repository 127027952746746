import { React, useEffect, useState } from "react";
import {Bs1CircleFill, Bs2CircleFill, Bs3CircleFill, Bs4CircleFill, Bs5CircleFill, Bs6CircleFill,Bs7CircleFill,Bs8CircleFill,Bs9CircleFill } from "react-icons/bs";
import Admissionimg from '../../img/admission.svg';
import TabAdmissionimg from '../../img/tabmobile.svg'
import { API_URL } from '../../setting/apiConfig';

const Ladyoffatima = () => {
    const options = {
        loop: true,
        margin: 10,
        items: 1,
        autoplay: true,
        responsive: {
           0: {
              items: 1,
              nav:true,
              
           },
           600: {
              items: 3,
           },
           1000: {
              items: 4,
           },
        },
     };
    const [universities, setUniversities] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
       fetch(`${API_URL}university-details`)
          .then((response) => response.json())
          .then((data) => {
             // Assuming 'results' contains the array of universities
             setUniversities(data);
             setIsLoading(false);
         
          })
          .catch((error) => {
             setIsLoading(false);
             console.error('Error fetching data:', error);
          });
    }, []);
    return (
        <>



            {/* section1 */}

            <div className='abt-sectionn pt-5 pb-5'>


                <div className='container'>

                    <h1>MBBS in Our Lady of Fatima University, Philippines</h1>

                    <div className='bds'></div>
                    <div id='brdrs'></div>

                    {/* <p>It is a long established fact that a reader.</p> */}


                </div>


            </div>




            <div className='container abt-2 mt-5 mb-5'>


                <p className='mb-4'>Welcome to Nayee Disha, your trusted partner in shaping your dreams of pursuing a remarkable medical education abroad! We are delighted to introduce you to Our Lady of Fatima University, a prestigious institution nestled in the captivating landscapes of the Philippines. For Indian students seeking a world-class MBBS program, Our Lady of Fatima University offers an exceptional opportunity to embark on a transformative journey towards a fulfilling medical career. With its unwavering commitment to academic excellence, state-of-the-art facilities, and a nurturing learning environment, this esteemed university stands as a beacon of success. Join us as we explore the captivating world of MBBS education at Our Lady of Fatima University, where endless possibilities await, and your aspirations to become a skilled and compassionate medical professional come to fruition.</p>
               
                <hr></hr>
                <div className='py-3 rounded'>

                    <div className='container mt-3'>
                        <h3 className='border_before'>Advantages of MBBS in MBBS in Our Lady of Fatima University, Philippines</h3>
                        <div className='row'>
                            <div className='col-md-3 mt-4 '>
                                <div className='bg-coloringrideant card-height  p-3 rounded'>
                                    <b className='f-19'>Global Recognition</b>
                                    <p className='f-17'>Acclaimed Degrees Opening the International Doors and Precious Chances</p>
                                </div>

                            </div>
                            <div className='col-md-3 mt-4 '>
                                <div className='bg-coloringrideant card-height p-3 rounded'>
                                    <b className='f-19'>Affordable Education</b>
                                    <p className='f-17'>Cost-Effective MBBS Programs without Compromising Quality and Rigour</p>
                                </div>

                            </div>
                            <div className='col-md-3 mt-4 '>
                                <div className='bg-coloringrideant card-height p-3 rounded'>
                                    <b className='f-19'>Experienced Faculty</b>
                                    <p className='f-17'>Renowned Professors Ensuring Comprehensive Medical Education Is Provided</p>
                                </div>

                            </div>
                            <div className='col-md-3 mt-4 '>
                                <div className='bg-coloringrideant card-height p-3 rounded'>
                                    <b className='f-19'>Cultural Diversity</b>
                                    <p className='f-17'>Exposure to Multicultural Environment Enhancing Global Perspective and Exposure</p>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className='container mb-4 mt-3'>
                        <div className='row'>
                            <div className='col-md-3 mt-4 '>
                                <div className='bg-coloringrideant card-height p-3 rounded'>
                            
                                

                                    <b className='f-19'>Advanced Infrastructure</b>
                                    <p className='f-17'>State-of-the-Art Facilities Are Provided Cutting-Edge Learning Opportunities</p>
                                </div>

                            </div>
                            <div className='col-md-3 mt-4 '>
                                <div className='bg-coloringrideant card-height p-3 rounded'>
                                    <b className='f-19'>High-Quality Teaching</b>
                                    <p className='f-17'>Rigorous Curriculum and Practical Training for Competent Medical Professionals</p>
                                </div>

                            </div>
                            <div className='col-md-3 mt-4 '>
                                <div className='bg-coloringrideant card-height p-3 rounded'>
                                    <b className='f-19'>English-Medium</b>
                                    <p className='f-17'>Language Barrier-Free Education Facilitating Easy Learning and Communication</p>
                                </div>

                            </div>
                            <div className='col-md-3 mt-4 '>
                                <div className='bg-coloringrideant card-height p-3 rounded'>
                                    <b className='f-19'>Career Opportunities</b>
                                    <p className='f-17'>Seamless Pathway to Explore Employment Options in Russia and Beyond.</p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>



                <hr></hr>




            </div>
            <div className='container'>
                <h3 className='border_before'>Facts about MBBS in Our Lady of Fatima University, Philippines</h3>
                <div className='row'>
                    <div className='col-md-12'>
                        <ul className='list-none mt-2'>
                            <li className='mt-1 '><Bs1CircleFill />&nbsp; Globally Recognized: Our Lady of Fatima University is recognized by international medical bodies, making your MBBS degree globally accepted and acknowledged.</li>
                            <li className='mt-2 '> <Bs2CircleFill /> &nbsp; High-Quality Education: The university offers a high-quality MBBS program that adheres to international standards, ensuring a strong foundation for your medical career.</li>
                            <li className='mt-2 '> <Bs3CircleFill />&nbsp; Experienced Faculty: Our Lady of Fatima University boasts a team of experienced faculty members who are experts in their respective fields, providing students with comprehensive guidance and support.</li>
                            <li className='mt-2 '> <Bs4CircleFill /> &nbsp; Clinical Training: The university provides extensive clinical training opportunities in leading hospitals and healthcare facilities, allowing students to gain practical experience and develop clinical skills.</li>
                            <li className='mt-2 '> <Bs5CircleFill /> &nbsp; State-of-the-Art Facilities: The university is equipped with state-of-the-art facilities, including modern laboratories, simulation centers, and research facilities, fostering an immersive learning experience.</li>
                            <li className='mt-2 '> <Bs6CircleFill /> &nbsp; English-Medium Program: The MBBS program at Our Lady of Fatima University is conducted in English, eliminating language barriers and ensuring effective communication and understanding.</li>
                            <li  className='mt-2 '><Bs7CircleFill /> &nbsp; Affordable Tuition Fees: The university offers competitive tuition fees, making quality medical education accessible and affordable for Indian students.</li>
                            <li  className='mt-2 '><Bs8CircleFill /> &nbsp; Multicultural Environment: Studying at Our Lady of Fatima University exposes students to a diverse and multicultural environment, promoting cross-cultural interactions and global networking opportunities.</li>
                            <li  className='mt-2 '><Bs9CircleFill /> &nbsp; Support Services: The university provides comprehensive support services, including accommodation assistance, visa guidance, and academic counseling, ensuring a smooth transition and a fulfilling educational journey.</li>
                            
                        </ul>
                    </div>

                </div>
            </div>
<hr></hr>
<div className='container'>
                <h3 className='border_before'>Admission Process and Documents Needed</h3>
                <div className='row'>
                    <div className='col-md-12'>
                      
                    </div>

                </div>
            </div>

<div className='container'>

<picture data-bs-toggle="modal"
data-bs-target="#exampleModal"
data-bs-whatever="@mdo">
{/* Mobile Image */}
<source media="(max-width: 576px)" loading="lazy" srcSet={TabAdmissionimg} />
{/* Tablet Image */}
<source media="(max-width: 992px)" loading="lazy" srcSet={TabAdmissionimg} />
{/* Desktop Image */}
<img
  className='w-100 mt-3'
   src={Admissionimg} // Fallback image for larger screens
   alt="Responsive slide"
   loading="lazy"
/>
</picture>
</div>

<br></br>

        </>
    );
};

export default Ladyoffatima;



