import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { useHistory } from 'react-router-dom'; // If using React Router v5
import { API_URL } from '../setting/apiConfig';

// Async thunk for fetching university data
export const fetchUniversityDetails = createAsyncThunk(
  'universityDetails/fetchUniversityDetails',
  async (id, { rejectWithValue }) => {
    try {
      const response = await fetch(`${API_URL}universityByTitle/${id}/`);
      if (response.status === 404) {
        return rejectWithValue({ status: 404, message: 'University not found' });
      }
      if (response.status >= 400 && response.status < 500) {
        throw new Error('Client error: Please check your request');
      }
      if (response.status >= 500) {
        throw new Error('Server error: Please try again later');
      }
      if (!response.ok) {
        throw new Error('Failed to fetch university details');
      }
      const data = await response.json();
      return data.data;
    } catch (error) {
      return rejectWithValue({ message: error.message });
    }
  }
);

const universityDetailsSlice = createSlice({
  name: 'universityDetails',
  initialState: {
    data: null,
    loading: false,
    error: null,
  },
  reducers: {
    clearUniversityDetails: (state) => {
      state.data = null;
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUniversityDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUniversityDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchUniversityDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.message || 'Something went wrong';

        // If status is 404, redirect to the 404 page
        if (action.payload?.status === 404) {
          // If inside a component, use history.push instead of window.location.href
          window.location.href = '/404';  // Alternative: history.push('/404')
        }
      });
  },
});

export const { clearUniversityDetails } = universityDetailsSlice.actions;
export default universityDetailsSlice.reducer;
