import { React, useEffect, useState } from "react";

import {Bs1CircleFill, Bs2CircleFill, Bs3CircleFill, Bs4CircleFill, Bs5CircleFill, Bs6CircleFill, Bs7CircleFill, Bs8CircleFill, Bs9CircleFill } from "react-icons/bs";
import Admissionimg from '../../img/admission.svg';
import { API_URL } from '../../setting/apiConfig';

const Jalalabad = () => {
  const options = {
    loop: true,
    margin: 10,
    items: 1,
    autoplay: true,
    responsive: {
      0: {
        items: 1,
        nav: true,

      },
      600: {
        items: 3,
      },
      1000: {
        items: 4,
      },
    },
  };
  const [universities, setUniversities] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    fetch(`${API_URL}university-details`)
      .then((response) => response.json())
      .then((data) => {
        // Assuming 'results' contains the array of universities
        setUniversities(data);
        setIsLoading(false);

      })
      .catch((error) => {
        setIsLoading(false);
        console.error('Error fetching data:', error);
      });
  }, []);
  return (
    <>



      {/* section1 */}

      <div className='abt-sectionn pt-5 pb-5'>


        <div className='container'>

          <h1>MBBS in Jalal-Abad State University, Kyrgyzstan</h1>

          <div className='bds'></div>
          <div id='brdrs'></div>

          {/* <p>It is a long established fact that a reader.</p> */}


        </div>


      </div>




      <div className='container abt-2 mt-5 mb-5'>


        <p className='mb-4'>Welcome to Nayee Disha, your trusted companion in shaping your dreams of pursuing a remarkable medical education abroad! We are thrilled to introduce you to Jalal-Abad State University, a distinguished institution located in the picturesque landscapes of Kyrgyzstan. For Indian students aspiring to become exceptional medical professionals, Jalal-Abad State University offers an extraordinary opportunity to embark on a transformative educational journey. With its unwavering commitment to academic excellence, experienced faculty, and a multicultural learning environment, this esteemed university stands as a beacon of success. Join us as we delve into the captivating world of MBBS education at Jalal-Abad State University, where endless possibilities await and your aspirations to make a difference in the field of medicine can thrive.</p>

        <hr></hr>
        <div className='py-3 rounded'>

          <div className='container mt-3'>
            <h3 className='border_before'>Advantages of MBBS in Perpetual Help System Dalta, Philippines</h3>
            <div className='row'>
              <div className='col-md-3 mt-4 '>
                <div className='bg-coloringrideant card-height  p-3 rounded'>
                  <b className='f-19'>Global Recognition</b>
                  <p className='f-17'>Acclaimed Degrees Opening the International Doors and Precious Chances</p>
                </div>

              </div>
              <div className='col-md-3 mt-4 '>
                <div className='bg-coloringrideant card-height p-3 rounded'>
                  <b className='f-19'>Affordable Education</b>
                  <p className='f-17'>Cost-Effective MBBS Programs without Compromising Quality and Rigour</p>
                </div>

              </div>
              <div className='col-md-3 mt-4 '>
                <div className='bg-coloringrideant card-height p-3 rounded'>
                  <b className='f-19'>Experienced Faculty</b>
                  <p className='f-17'>Renowned Professors Ensuring Comprehensive Medical Education Is Provided</p>
                </div>

              </div>
              <div className='col-md-3 mt-4 '>
                <div className='bg-coloringrideant card-height p-3 rounded'>
                  <b className='f-19'>Cultural Diversity</b>
                  <p className='f-17'>Exposure to Multicultural Environment Enhancing Global Perspective and Exposure</p>
                </div>

              </div>
            </div>
          </div>

          <div className='container mb-4 mt-3'>
            <div className='row'>
              <div className='col-md-3 mt-4 '>
                <div className='bg-coloringrideant card-height p-3 rounded'>



                  <b className='f-19'>Advanced Infrastructure</b>
                  <p className='f-17'>State-of-the-Art Facilities Are Provided Cutting-Edge Learning Opportunities</p>
                </div>

              </div>
              <div className='col-md-3 mt-4 '>
                <div className='bg-coloringrideant card-height p-3 rounded'>
                  <b className='f-19'>High-Quality Teaching</b>
                  <p className='f-17'>Rigorous Curriculum and Practical Training for Competent Medical Professionals</p>
                </div>

              </div>
              <div className='col-md-3 mt-4 '>
                <div className='bg-coloringrideant card-height p-3 rounded'>
                  <b className='f-19'>English-Medium</b>
                  <p className='f-17'>Language Barrier-Free Education Facilitating Easy Learning and Communication</p>
                </div>

              </div>
              <div className='col-md-3 mt-4 '>
                <div className='bg-coloringrideant card-height p-3 rounded'>
                  <b className='f-19'>Career Opportunities</b>
                  <p className='f-17'>Seamless Pathway to Explore Employment Options in Russia and Beyond.</p>
                </div>

              </div>
            </div>
          </div>
        </div>



        <hr></hr>




      </div>
      <div className='container'>
        <h3 className='border_before'>Advantages of MBBS in Jalal-Abad State University, Kyrgyzstan</h3>
        <div className='row'>
          <div className='col-md-12'>
            <ul className='list-none mt-2'>
              <li className='mt-1 '><Bs1CircleFill />&nbsp; Recognized by WHO and MCI: Jalal-Abad State University is recognized by the World Health Organization (WHO) and the Medical Council of India (MCI), ensuring the global recognition and acceptance of your MBBS degree.</li>
              <li className='mt-2 '> <Bs2CircleFill /> &nbsp; Affordable Tuition Fees: The university offers an affordable MBBS program, making it a cost-effective option for Indian students seeking quality medical education abroad.</li>
              <li className='mt-2 '> <Bs3CircleFill />&nbsp; Experienced Faculty: Jalal-Abad State University boasts a team of experienced faculty members who are dedicated to providing quality education and mentorship to students.</li>
              <li className='mt-2 '> <Bs4CircleFill /> &nbsp; English-Medium Program: The MBBS program at Jalal-Abad State University is conducted in English, eliminating language barriers and ensuring effective communication and understanding.</li>
              <li className='mt-2 '> <Bs5CircleFill /> &nbsp; Clinical Training: The university provides ample clinical training opportunities in reputed hospitals, allowing students to gain practical experience and develop clinical skills.</li>
              <li className='mt-2 '> <Bs6CircleFill /> &nbsp; Modern Infrastructure: Jalal-Abad State University is equipped with modern facilities, including well-equipped laboratories and research centers, fostering a conducive learning environment.</li>
              <li className='mt-2 '><Bs7CircleFill /> &nbsp; Multicultural Environment: Studying at Jalal-Abad State University offers exposure to a multicultural environment, promoting cross-cultural interactions and global networking opportunities.</li>
              <li className='mt-2 '><Bs8CircleFill /> &nbsp; Student Support Services: The university provides comprehensive support services, including accommodation assistance, visa guidance, and student counseling, ensuring a smooth transition and a comfortable stay.</li>
              <li className='mt-2 '><Bs9CircleFill /> &nbsp; Safe Campus: Jalal-Abad State University maintains a safe campus environment with security measures in place, ensuring the well-being and security of all students.</li>

            </ul>
          </div>

        </div>
      </div>
      <hr></hr>
      <div className='container'>
        <h3 className='border_before'>Admission Process and Documents Needed</h3>
        <div className='row'>
          <div className='col-md-12'>

          </div>

        </div>
      </div>

      <div className='container'>

        <img src={Admissionimg} alt="img" loading="lazy" className='w-100 mt-3' />
      </div>

      <br></br>
    
    </>
  );
};

export default Jalalabad;





