import { useState, Fragment, useEffect } from "react";
import "./sidebar.css";
import "./Aside/nav.css"
import { useLocation } from "react-router";
import Header from './components/header';
import Footer from './components/footer';
import Routing from "./router";
import { useMediaQuery } from "react-responsive";
import Aside from "../src/Aside";
import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async'; 
import store from './store/store'; // Adjust the path as needed


function App() {
  const [collapsed, setCollapsed] = useState(false);
  const [toggled, setToggled] = useState(false);


  const handleToggleSidebar = (value) => {
    setToggled(value);
  };
  const handleToggle = () => {
    setToggled(prev => !prev);
  };




  const isTabletOrMobile = useMediaQuery({ maxWidth: 768 });
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    // Disable right-click
    // handleContextMenu = (e) => {
     // e.preventDefault();
   // };

    // Disable copy
    //const handleCopy = (e) => {
     // e.preventDefault();
     // alert("Copying is disabled on this page.");
   // };

    // Disable paste
    //const handlePaste = (e) => {
     // e.preventDefault();
     // alert("Pasting is disabled on this page.");
    //};

    // Add event listeners
    //document.addEventListener("contextmenu", handleContextMenu);
    //document.addEventListener("copy", handleCopy);
    //document.addEventListener("paste", handlePaste);

    // Cleanup event listeners on component unmount
    return () => {
     // document.removeEventListener("contextmenu", handleContextMenu);
     // document.removeEventListener("copy", handleCopy);
     // document.removeEventListener("paste", handlePaste);
    };
  }, []);

  // const [isDarkMode, setIsDarkMode] = useState(() => false);

  return (
<Provider store={store}>
<HelmetProvider>
    <Fragment>

        {isTabletOrMobile ? (
          <Aside
            collapsed={collapsed}
            toggled={toggled}
            handleToggleSidebar={handleToggleSidebar}
            handleToggle={handleToggle}
          />
        ) : (
          ""
        )}
      <Header isMobile={isTabletOrMobile}
          toggled={toggled}
          handleToggleSidebar={handleToggleSidebar} />
        <Routing />
      <Footer />
    </Fragment>
    </HelmetProvider>
    </Provider>
  );
}

export default App;

