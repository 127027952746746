import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API_URL } from '../setting/apiConfig';
// Async thunk to fetch active data
export const fetchActiveData = createAsyncThunk(
  'navigationData/fetchActiveData',
  async () => {
    const response = await fetch(`${API_URL}universityByTitle/?status=active`);
    const result = await response.json();
    return result.data; // Extracting only the `data` array
  }
);

const navigationDataSlice = createSlice({
  name: 'navigationData',
  initialState: {
    list: [], // This will store the `data` array
    loading: false,
    error: null,
  },
  reducers: {
    clearData: (state) => {
      state.list = [];
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchActiveData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchActiveData.fulfilled, (state, action) => {
        state.loading = false;
        state.list = action.payload; // Storing only the `data` array
      })
      .addCase(fetchActiveData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { clearData } = navigationDataSlice.actions;
export default navigationDataSlice.reducer;
