import React, { useEffect, useState } from "react";
import { useParams, Redirect} from 'react-router-dom';
// import { Helmet } from "react-helmet";
import { HelmetProvider, Helmet } from 'react-helmet-async';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { API_URL, BASE_URL } from '../setting/apiConfig';

// Initialize a simple in-memory cache
const cache = {};

const BlogDetails = () => {
    const { slug } = useParams();
    const [blogdetails, setBlogdetails] = useState(null);
    const [notFound, setNotFound] = useState(false);


    const [bloglist, setBlogList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchBlogDetails = async () => {
            setIsLoading(true);
            if (cache.blogdetails) {
                setBlogList(cache.blogdetails);
                setIsLoading(false);
            } else {
                try {
                    const response = await fetch(`${API_URL}blog-details`);
                    const data = await response.json();
                    cache.blogdetails = data;
                    setBlogList(data);
                } catch (error) {
                    console.error('Error fetching blog details:', error);
                } finally {
                    setIsLoading(false);
                }
            }
        };

        fetchBlogDetails();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            // Check if the data is already in the cache
            if (cache[slug]) {
                setBlogdetails(cache[slug]);
                return;
            }

            try {
                const response = await fetch(`${API_URL}blogs/${slug}`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                if (data) {
                    // Store the fetched data in the cache
                    cache[slug] = data;
                    setBlogdetails(data);
                } else {
                    setNotFound(true);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
                setNotFound(true);
            }
        };

        fetchData();
    }, [slug]);

    if (notFound) {
        return <Redirect to="/404" />;
    }

    const createdAtDate = blogdetails ? new Date(blogdetails.created_at) : null;
    const formattedDate = createdAtDate ? `${createdAtDate.getDate()} ${['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'][createdAtDate.getMonth()]} ${createdAtDate.getFullYear()}` : '';

    return (
        <>
            <div className="application">
                <HelmetProvider>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>{blogdetails?.metatitle || 'Loading...'}</title>
                        <meta name="description" content={blogdetails?.metadescription || 'Loading...'} />
                        <meta name="keywords" content={blogdetails?.metakeywords || 'Loading...'} />
                        <link rel="canonical" href={blogdetails?.slug || 'Loading...'} />
                        <meta property="og:title" content={blogdetails?.metatitle || 'Loading...'} />
                        <meta property="og:description" content={blogdetails?.metadescription || 'Loading...'} />
                        <meta property="og:image" content={blogdetails?.thumbnail || 'Loading...'} />
                        <meta property="og:type" content="website" />
                    </Helmet>
                </HelmetProvider>
            </div>

            {/* section1 */}
            <div className='abt-section-news pt-5 pb-5'>
                <div className='container'>
                    <h3 className="text-white">{blogdetails?.title || <Skeleton width={200} />}</h3>
                    <div className='bds'></div>
                    <div id='brdrs'></div>
                </div>
            </div>

            <div className='container abt-2 mt-5 mb-5'>
                <div className="row">
                    <div className="col-md-9">
                        <div className="news-paragraphbg">
                            {blogdetails ? (
                                <>
                                    <img src={blogdetails.thumbnail} className="w-100" loading="lazy" alt="blog thumbnail" />
                                    <p className="mx-2 mt-3">{formattedDate}</p>
                                    <div className='mb-4 news-paragraph p-2' dangerouslySetInnerHTML={{ __html: blogdetails.content || '' }} />
                                </>
                            ) : (
                                <>
                                    <Skeleton height={200} />
                                    <Skeleton count={5} />
                                </>
                            )}
                        </div>
                    </div>
                    <div className="col-md-3">
                        <h5>Recent Blogs</h5>
                        <div className="row">

                            <div className="col-md-12">
                                <img src="https://media.nayeedisha.in/blog_thumbnails/Mbbs_Abroad_Education_Consultants_in_Gorakhpur.png" loading="lazy" className="w-100 " alt="MBBS Abroad Education Consultants in Gorakhpur" />
                            </div>
                            <div className="card-body">

                                <div className="col-md-12 col-lg-12 col-12 px-3 py-2">
                                    <b style={{ textTransform: 'capitalize' }}> <a href={`${BASE_URL}blogs/mbbs-abroad-education-consultants-in-gorakhpur`}><b style={{ textTransform: 'capitalize' }}>MBBS Abroad Education Consultants in Gorakhpur..</b></a></b>
                                </div>

                            </div>

                            <hr></hr>
                            <div className="col-md-12">
                                <img src="https://media.nayeedisha.in/blog_thumbnails/MBBS-IN-UZBEKISTAN-An-Emerging-Destination-for-Indian-Medical-Students.png" loading="lazy" className="w-100 " alt="MBBS Abroad Education Consultants in Gorakhpur" />
                            </div>

                            <div className="col-md-12 col-lg-12 col-12  px-3 py-2"><b style={{ textTransform: 'capitalize' }}><a href={`${BASE_URL}blogs/mbbs-in-uzbekistan-an-emerging-destination-for-indian-medical-students`}>  <b style={{ textTransform: 'capitalize' }}>MBBS IN UZBEKISTAN:  An Emerging Destination</b></a></b>
                            </div>

<hr></hr>
                            <div className="col-md-12 px-3 py-2">
                                <img src="https://media.nayeedisha.in/blog_thumbnails/How-to-get-admission-in-Government-MBBS-Colleges.png" className="w-100 " alt="MBBS Abroad Education Consultants in Gorakhpur" />
                            </div>

                            <div className="col-md-12 col-lg-12 col-12"><b style={{ textTransform: 'capitalize' }}> <a href={`${BASE_URL}blogs/how-to-get-admission-in-government-mbbs-colleges`}>  <b style={{ textTransform: 'capitalize' }}>How to Get Admission in Government MBBS..</b> </a></b>
                            </div>







                        </div>


                        <hr></hr>
                    </div>
                </div>
            </div>
        </>
    );
};

export default BlogDetails;