import  React, { useEffect, useState } from "react";

import { Bs1CircleFill, Bs2CircleFill, Bs3CircleFill, Bs4CircleFill, Bs5CircleFill, Bs6CircleFill, Bs7CircleFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { API_URL, BASE_URL } from '../../setting/apiConfig';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import { fetchActiveData } from '../../store/navigationDataSlice';
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";



const MbbsinAbroad = () => {
    const dispatch = useDispatch();

    const [universities, setUniversities] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        fetch(`${API_URL}university-details`)
            .then((response) => response.json())
            .then((data) => {
                // Assuming 'results' contains the array of universities
                setUniversities(data);
                setIsLoading(false);

            })
            .catch((error) => {
                setIsLoading(false);
                console.error('Error fetching data:', error);
            });
    }, []);


    const [russiaUniversities, setRussiaUniversities] = useState([]);
    const [nepalUniversities, setNepalUniversities] = useState([]);


    // show university according country
    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);

                // Check if data is available in localStorage
                const cachedData = localStorage.getItem('university-details');

                if (cachedData) {
                    const universityData = JSON.parse(cachedData); // Use cached data

                    // Filter universities for Russia and Nepal
                    const russiaFiltered = universityData.filter(university => university.country === 'Russia');
                    const nepalFiltered = universityData.filter(university => university.country === 'Nepal');

                    // Update state with filtered data
                    setRussiaUniversities(russiaFiltered);
                    setNepalUniversities(nepalFiltered);

                    setIsLoading(false);
                } else {
                    const universityResponse = await fetch(API_URL+ 'university-details');
                    const universityData = await universityResponse.json();

                    // Store fetched data in localStorage
                    localStorage.setItem('university-details', JSON.stringify(universityData));
                    console.log(universityData);
                    // Filter universities for Russia and Nepal
                    const russiaFiltered = universityData.filter(university => university.country === 'Russia');
                    const nepalFiltered = universityData.filter(university => university.country === 'Nepal');

                    // Update state with filtered data
                    setRussiaUniversities(russiaFiltered);
                    setNepalUniversities(nepalFiltered);

                    setIsLoading(false);
                }
            } catch (error) {
                setIsLoading(false);
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    const { list, loading, error } = useSelector((state) => state.navigationData);
    const truncateContent = (content, wordLimit) => {
        if (!content) return 'No content';
      
        // Use DOMParser to convert HTML to plain text
        const doc = new DOMParser().parseFromString(content, 'text/html');
        const textContent = doc.body.textContent || doc.body.innerText; // Get the plain text
      
        // Split text into words
        const words = textContent.split(' ');
      
        // Check if the content exceeds the word limit
        if (words.length > wordLimit) {
          return words.slice(0, wordLimit).join(' ') + '...';
        }
      
        return textContent; // Return the plain text content if it's within the limit
      };
      

    const textContent = 8; // Set 
    useEffect(() => {
        dispatch(fetchActiveData()); // Fetch active universities on component mount
      }, [dispatch]);
    
      if (loading) return (
        <div>
            <Skeleton count={5} height={30} />
            <Skeleton count={3} height={50} />
        </div>
    );
    
    
      if (error) {
        return <p>Error: {error}</p>;
      }
      if (list.length === 0) {
        return <div>
        <Skeleton count={5} height={10} />
        <Skeleton count={3} height={10} />
    </div>;
      }
    const options = {
        loop: true,
        margin: 10,
        items: 1,
        autoplay: true,
        responsive: {
            0: {
                items: 1,
                nav: true,

            },
            600: {
                items: 3,
            },
            1000: {
                items: 4,
            },
        },
    };
  
    return (
        <>



            {/* section1 */}

            <div className='abt-sectionn pt-5 pb-5'>


                <div className='container'>

                    <h3>MBBS in <span>Abroad</span></h3>


                    <Breadcrumb>
                        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                        <Breadcrumb.Item className="text-white" active>
                            MBBS In Abroad
                        </Breadcrumb.Item>

                    </Breadcrumb>
                    <div className='bds'></div>
                    <div id='brdrs'></div>

                    {/* <p>It is a long established fact that a reader.</p> */}


                </div>


            </div>




            <div className='container abt-2 mt-5 mb-5'>


                <p className='mb-4'>
                    MBBS abroad has been a key option for many students who want to get quality education, international learning experience and affordable fees structure. Due to high cut off and as well as low number of seats available in the medical colleges of India, most of the students want to pursue MBBS in foreign countries to become a doctor. The countries include Russia, Kyrgyzstan, Georgia,  Kazakhstan, and China that provide A-level MBBS degree recognized by WHO, NMC and other international organizations.

                </p>
                <p>One of the major factors of pursuing MBBS in a foreign country is the huge difference in the cost of tuition fees and other expenses as compared to private medical colleges in India. The foreign universities provide numerous facilities such as modem Laboratories, competent trainers, Preparing students for the actual hand  in experience in the medical line.
                </p>
                <p>Furthermore, Indian students who graduate from NMC recognised universities abroad get the opportunity to practice in India after clearing the FMGE/NEXT exam which is mandatory for students who have completed their mbbs course in India and also who have pursued MBBS abroad. Foreign universities also provide assistance for the Indian students in completing these licensing examinations.
                </p>
                <p>MBBS abroad open ample opportunities and develop those aspects which are required to build a successful career in the field of medical profession. It is a perfect option for anybody interested in the medical field.
                </p>
            </div>
            <section id="mbbs_abroad" className="py-4 bg-light">
                <div className="container">

                    <h2 className="h3 fw-bold my-3">
                        MBBS Abroad VS
                        <span className="text-primary">&nbsp; MBBS India</span>
                    </h2>
                    <Table striped bordered hover className="mt-3">
                        <thead>
                            <tr>
                                <th>PARAMETERS</th>
                                <th>MBBS ABROAD</th>
                                <th>MBBS INDIA</th>

                            </tr>
                        </thead>
                        <tbody>
                            <tr>

                                <td>Entrance requirements</td>
                                <td>NEET qualifying is mandatory. Only required qualifying NEET percentile score for admission </td>
                                <td>NEET qualifying is mandatory but the admission is based on the score and cut off which goes very high.</td>
                            </tr>
                            <tr>

                                <td>Tuition fees</td>
                                <td>15-30 Lakhs</td>
                                <td>50 lakhs - INR 1.5 crore for entire course, depending on the institution </td>
                            </tr>
                            <tr>
                                <td>Quality of education </td>
                                <td>Emphasis is on practical training and hand in experience.</td>
                                <td>Quality varies widely, Private universities tend to focus more on quality infrastructure than education.

                                </td>
                            </tr>

                            <tr>
                                <td>Practical exposure </td>
                                <td>International learning exposure and opportunity to learn through diverse patient base and practical training

                                </td>
                                <td>
                                    Learning and training is only focused on the Indian health care system.
                                </td>
                            </tr>

                            <tr>
                                <td>Seat availability</td>
                                <td>High</td>
                                <td>Limited availability of seats also means there are three types of seats: govt seat, management and NRI which makes the chances of admission less due to quota and competition.</td>
                            </tr>

                            <tr>
                                <td>Global exposure</td>
                                <td>Lot of global exposure since there are students across the globe</td>
                                <td>No global exposure</td>
                            </tr>
                            <tr>
                                <td>Teacher student ratio</td>
                                <td>1:8 to 1:15</td>
                                <td>1:30 to 1:40</td>
                            </tr>
                        </tbody>
                    </Table>
                    <br></br>

                </div>
            </section>
            <section id="eligibilitycriteria" className="py-4">
                <div className="container">






                    <h3 className="fw-bold my-3">
                        ELIGIBILITY
                        <span className="text-primary">&nbsp; CRITERIA</span>
                    </h3>
                    <Table striped bordered hover className="mt-3">
                        <thead>
                            <tr>
                                <th>CRITERIA</th>
                                <th>REQUIREMENTS</th>


                            </tr>
                        </thead>
                        <tbody>
                            <tr>

                                <td>Academic Qualification</td>
                                <td>10+2 with a minimum 50 % score in PCB/PCM (Physics, Chemistry, and Biology) (Note- PCM is applicable from 2024.)
                                </td>
                            </tr>
                            <tr>

                                <td>Age Requirement</td>
                                <td>Minimum 17 years of age by the 31st December of the admission year.
                                </td>
                            </tr>
                            <tr>
                                <td>NEET Requirement </td>
                                <td>Minimum of 50% for the General/EWS category 40% for the Unreserved Category SC/ST and OBC.</td>


                            </tr>


                        </tbody>
                    </Table>
                </div>
            </section>
            <section id="mbbs_abroad" className="py-4 bg-light">
                <div className="container">

                    <h3 className="fw-bold my-3">
                        MBBS Abroad VS
                        <span className="text-primary">&nbsp; MBBS India</span>
                    </h3>
                    <Table striped bordered hover className="mt-3">
                        <thead>
                            <tr>
                                <th>CRITERIA</th>
                                <th>REQUIREMENTS</th>


                            </tr>
                        </thead>
                        <tbody>
                            <tr>

                                <td>Academic Qualification</td>
                                <td>10+2 with a minimum 50 % score in PCB/PCM (Physics, Chemistry, and Biology) (Note- PCM is applicable from 2024.)
                                </td>
                            </tr>
                            <tr>

                                <td>Age Requirement</td>
                                <td>Minimum 17 years of age by the 31st December of the admission year.
                                </td>
                            </tr>
                            <tr>
                                <td>NEET Requirement </td>
                                <td>Minimum of 50% for the General/EWS category 40% for the Unreserved Category SC/ST and OBC.</td>


                            </tr>


                        </tbody>
                    </Table>
                    <br></br>

                </div>
            </section>
            <div className='container'>

                <h3>Documentation <span>Requirement:</span></h3>
                <div className='row'>
                    <div className='col-md-12'>
                        <ul className='list-none mt-2'>
                            <li className='mt-1'><Bs1CircleFill />&nbsp; Pass certificates and mark sheets of classes 10th and 12th.</li>
                            <li className='mt-2 '> <Bs2CircleFill /> &nbsp; Health certificate/report</li>
                            <li className='mt-2 '> <Bs3CircleFill />&nbsp; Valid passport</li>
                            <li className='mt-2 '> <Bs3CircleFill />&nbsp; Birth certificate</li>
                            <li className='mt-2 '> <Bs3CircleFill />&nbsp; Invitation letter</li>

                            <li className='mt-2 '> <Bs3CircleFill />&nbsp; Passport size photos</li>
                            <li className='mt-2 '> <Bs3CircleFill />&nbsp; NEET scorecard</li>

                            <li className='mt-2 '> <Bs4CircleFill /> &nbsp;The university offers undergraduate, postgraduate, and doctoral programs in various medical disciplines.</li>
                            <li className='mt-2 '><Bs5CircleFill /> &nbsp;The university places a strong emphasis on practical training, offering clinical rotations and internships in reputed medical institutions.</li>
                        </ul>
                    </div>

                </div>
            </div>
            <hr></hr>

            <br></br>

            <div className='container'>
                <h3 className="fw-bold ">Countries List :</h3>
                <div className='row'>
                {list.map((data) => (
                <div className="col-md-3 mt-3 mb-5">
      
         <Link to={data.slug} className="text-dark"><Card>
            <Card.Img variant="top" src={data.thumbnail || '#'} className="img-fluid" loading="lazy" style={{width: '100',height: '150px',objectFit: 'cover', // Ensures the image fills the area without distortionborderRadius: '8px', // Optional: Adds rounded corners
}}  />
            <Card.Body>
              <Card.Title style={{fontZize: 18,
    fontWeight: 700}}>{data.title || 'No Title'}</Card.Title>
              <Card.Text>{truncateContent(data.content, textContent)}</Card.Text>
              
              <Button variant="outline-success" className="btn btn-outline-primary">Read More</Button>
            </Card.Body>
          </Card>
          </Link>
      </div>
            ))}
               




                </div>
            </div>
            <hr className="mt-5 pt-5"></hr>
            <div className="container">
                <h3 className='border_before'>Popular Universities In Russia</h3>
                <h2 className="h3 fw-bold my-3">Popular Universities<span className="text-primary">&nbsp; In Russia</span></h2>
                <div className="row">




                    {russiaUniversities.map((data) => {
                        return (
                            <div className="col-md-4 mt-4">
                                <Link to={`/university/${data.slug}`} key={data.slug}>

                                    <div className="card" id="icons-crd" style={{ width: '100%' }}>
                                        <img src={data.cover_image} className="card-img-top" loading="lazy" alt="NayeeDisha" />
                                        <span class="universitytype border-radius-5 text-white"> Private </span>
                                        <div className="card-body iconerss-card">
                                            <img src={data.logo} loading="lazy" alt="NayeeDisha" />
                                            <Link to={`/university/${data.slug}`}>
                                                <h5 className="mt-1 text-transform">{data.name}</h5>
                                            </Link>
                                            <hr />
                                            <div>
                                                <div className='pt-2 college_part'>

                                                    {data.total_budget && data.total_budget.length > 0 ? (
                                                        data.total_budget.map((budget, index) => (
                                                            <div key={index}>


                                                                <p className='para1' style={{ color: '#000' }}>Fee <span className='ugc' style={{ color: '#000' }}>&nbsp;&nbsp;&nbsp;: {budget.total_budget ? budget.total_budget : 'Not found'} </span></p>

                                                            </div>
                                                        ))
                                                    ) : (
                                                        <p>Not available</p>
                                                    )}

                                                    {/* <p className='para1' style={{ color: '#000' }}>Fee <span className='ugc' style={{ color: '#000' }}>: {data.total_budget.total_budget ? data.total_budget.total_budget : 'Not found'} </span></p> */}
                                                    <p className='para1'>Estd. Year <span className='ugc' style={{ color: '#000' }}>&nbsp;&nbsp;&nbsp;: {data.estb ? data.estb : 'Not found'} </span></p>
                                                </div>

                                            </div>
                                            <div>
                                                <div className='college_part'>
                                                    <p className='para1'>Country <span className='ugc' style={{ color: '#000' }}> &nbsp;&nbsp;&nbsp;: {data.country ? data.country : 'Not found'} </span></p>

                                                    {data.ranking && data.ranking.length > 0 ? (
                                                        data.ranking.map((rank, index) => (
                                                            <p className="para1" key={index} style={{ color: '#000' }}>
                                                                {rank.authority}{' '}
                                                                <span className="ugc" style={{ color: '#000' }}>
                                                                    : {rank.ranking ? rank.ranking : 'Not found'}
                                                                </span>
                                                            </p>
                                                        ))
                                                    ) : (
                                                        <p className="para1" style={{ color: '#000' }}>
                                                            World Rank{' '}
                                                            <span className="ugc" style={{ color: '#000' }}>
                                                                : Not found
                                                            </span>
                                                        </p>
                                                    )}
                                                    {/* <p className='para1' style={{ color: '#000' }}> World Rank<span className='ugc' style={{ color: '#000' }}>  : {data.ranking.ranking ? data.ranking.ranking : 'Not found'} </span></p> */}
                                                </div>
                                                <div className='d-flex justify-content-between mt-3'>
                                                    <Button type="button" className="btn btn-primary px-4 p-2 " data-bs-toggle="modal" data-bs-target="#exampleModal" data-bs-whatever="@mdo" id='btnr1'>
                                                        Apply Now &nbsp;&nbsp;&nbsp;
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10" fill="none">
                                                            <path fillRule="evenodd" clipRule="evenodd" d="M5.78009 4.45975C5.9209 4.6006 6 4.79161 6 4.99077C6 5.18993 5.9209 5.38094 5.78009 5.52179L1.53118 9.7707C1.46189 9.84244 1.37902 9.89966 1.28738 9.93902C1.19574 9.97839 1.09719 9.99911 0.997458 9.99997C0.897728 10.0008 0.798825 9.98183 0.706519 9.94407C0.614213 9.9063 0.530352 9.85053 0.45983 9.78001C0.389308 9.70949 0.333538 9.62563 0.295773 9.53332C0.258007 9.44102 0.239003 9.34211 0.23987 9.24238C0.240736 9.14266 0.261456 9.0441 0.30082 8.95246C0.340184 8.86083 0.397403 8.77795 0.46914 8.70866L4.18703 4.99077L0.469139 1.27288C0.332322 1.13122 0.256617 0.941496 0.258328 0.744563C0.260039 0.54763 0.33903 0.359248 0.478288 0.219989C0.617547 0.0807309 0.805929 0.001739 1.00286 2.80934e-05C1.1998 -0.00168282 1.38952 0.0740227 1.53118 0.210839L5.78009 4.45975Z" fill="white" />
                                                        </svg>
                                                    </Button>
                                                    <a href={`/university/${data.slug}`}>
                                                        <Button className="btn" variant="outline-success">Read More</Button>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>

                        );

                    })}
                </div>
            </div>
            <br></br>
        </>
    );
};

export default MbbsinAbroad;
