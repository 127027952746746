import axios from 'axios';
import { API_URL, BASE_URL } from '../../setting/apiConfig';

export const FETCH_COURSES_SUCCESS = 'FETCH_COURSES_SUCCESS';
export const FETCH_COURSES_FAILURE = 'FETCH_COURSES_FAILURE';

export const fetchCourses = (filters = {}) => async (dispatch) => {
  try {
    // Remove empty filters to avoid sending unnecessary keys
    const cleanedFilters = Object.fromEntries(
      Object.entries(filters).filter(([_, v]) => v !== '' && v !== undefined)
    );

    const response = await axios.post(`${API_URL}filter-universities`, cleanedFilters);
    dispatch({
      type: FETCH_COURSES_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_COURSES_FAILURE,
      payload: error.message,
    });
  }
};
