import { React, useState, useEffect } from "react";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from "react-pro-sidebar";
import { Link } from "react-router-dom";
import nayeedishalogosfin from "../img/nayeedishalogos-fin.svg";
import { API_URL } from '../setting/apiConfig';
import ReactSearchBox from "react-search-box";
import { FaPlus, FaMinus } from "react-icons/fa";


const Aside = ({ collapsed, toggled, handleToggleSidebar, handleToggle }) => {
  const [universities, setUniversities] = useState({ Russia: [], India: [], Nepal: [] });
  const [isloading, setIsLoading] = useState(true);

  const [universityindiaList, setUniversityindiaList] = useState([]); 


  useEffect(() => {
    const cachedData = localStorage.getItem("universitiesindia");
    const cacheTime = localStorage.getItem("universities_cache_time");
    const cacheDuration = 5 * 60 * 1000; // 5 minutes
  
    if (cachedData && cacheTime && Date.now() - cacheTime < cacheDuration) {
      setUniversityindiaList(JSON.parse(cachedData)); // Use cached data
    } else {
      fetch(`${API_URL}universityByTitle/list_india/?status=active`)
        .then((response) => response.json())
        .then((data) => {
          setUniversityindiaList(data.data);
          localStorage.setItem("universitiesindia", JSON.stringify(data.data)); // Cache data
          localStorage.setItem("universities_cache_time", Date.now()); // Update timestamp
        })
        .catch((error) => console.error("Error fetching data:", error));
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);

        const universityResponse = await fetch(API_URL+ 'university-details');
        const universityData = await universityResponse.json();

        // Group universities by country
        const filteredUniversities = {
          Russia: universityData.filter(university => university.country === 'Russia'),
          India: universityData.filter(university => university.country === 'India'),
          Nepal: universityData.filter(university => university.country === 'Nepal'),
        };

        setUniversities(filteredUniversities);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const [openSubMenus, setOpenSubMenus] = useState({});
  const [activeSubMenu, setActiveSubMenu] = useState(null); // Tracks the currently active menu

  const handlesubToggle = (menuKey) => {
    setActiveSubMenu((prevKey) => (prevKey === menuKey ? null : menuKey)); // Close if open, otherwise open
  };

  const searchData = [
    {
      key: "ani",
      value: "Aniket",
      url: "https://word.com/"
    },
    {
      key: "jane",
      value: "Jane Doe",
      url: "https://facebook.com/"
    },
    {
      key: "mary",
      value: "Mary Phillips",
      url: "https://example.com/mary"
    },
    {
      key: "robert",
      value: "Robert",
      url: "https://example.com/robert"
    },
    {
      key: "karius",
      value: "Karius",
      url: "https://example.com/karius"
    }
  ];
  const onSelect = (record) => {
    window.location.href = record.url;
  
  };

  const renderOption = (record, index) => {
    return (
      <div key={index} onClick={() => onSelect(record)}>
        <span>{record.value}</span>
        <span style={{ marginLeft: '5px', color: '#999' }}>{record.url}</span>
      </div>
    );
  };

  return (

    <ProSidebar
      collapsed={collapsed}
      toggled={toggled}
      breakPoint="md"
      onToggle={handleToggleSidebar}
    >
      <SidebarHeader>
        <div
          style={{
            padding: "24px",
            textTransform: "uppercase",
            fontWeight: "bold",
            fontSize: 14,
            letterSpacing: "1px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          <img src={nayeedishalogosfin} alt="logo" loading="lazy" className="logomibile-image"></img>
        </div>



        {/*  */}
        <div className='p-2'>

          <ReactSearchBox
            placeholder="Search for MBBS, Btech or More"
            data={searchData}
            onSelect={onSelect}
            renderOption={renderOption}
          />



        </div>


      </SidebarHeader>

      <SidebarContent>

        <Menu iconShape="">
          <Link onClick={() => handleToggle()} to={"/"}> <MenuItem  > Home</MenuItem></Link>
          <Link onClick={() => handleToggle()} to={"/about-us"}> <MenuItem  > About Nayee Disha</MenuItem></Link>
          <Link onClick={() => handleToggle()} to={"/we-provide"}>  <MenuItem  >  We Provide</MenuItem></Link>
          <SubMenu
        title={
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <span>Study In India</span>
            {activeSubMenu === "studyInIndia" ? <FaMinus /> : <FaPlus />}
          </div>
        }
        onOpenChange={() => handlesubToggle("studyInIndia")}
        suffix={<span className="badge yellow"></span>}
        open={activeSubMenu === "studyInIndia"} // Opens only if this is the active menu
      >
      {/* <Link to={"/mbbs-in-india"}> */}
   


      {universityindiaList.map(universityindia => (
                        
                          <a href={`/${universityindia.slug}`}>
                          <MenuItem> {universityindia.title}</MenuItem>
                          </a>
                       
                      ))}
    </SubMenu>

       
    <SubMenu
        title={
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <span>Study Abroad</span>
            {activeSubMenu === "studyAbroad" ? <FaMinus /> : <FaPlus />}
          </div>
        }
        onOpenChange={() => handlesubToggle("studyAbroad")}
        suffix={<span className="badge yellow"></span>}
      // Opens only if this is the active menu
      >

            
                <SubMenu
        title={
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <a href="/mbbs-in-russia"><span>MBBS In Russia</span></a>
            {activeSubMenu === "russia" ? <FaMinus /> : <FaPlus />}
          </div>
        }
        onOpenChange={() => handlesubToggle("russia")}
        suffix={<span className="badge yellow"></span>}
        open={activeSubMenu === "russia"}
      >
            {universities.Russia.map((university, index) => (


                // <Link  key={index} onClick={() => handleToggle()} to={`/university/${university.slug}`}>
                //   <MenuItem className="sub-menu">- {university.name}  </MenuItem>
                // </Link>

                <a key={index} onClick={() => handleToggle()} href={`/university/${university.slug}`} >
                <MenuItem className="sub-menu">- {university.name}  </MenuItem>
                                </a>
              ))}

            </SubMenu>

       


            <SubMenu
        title={
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
           <a href="/mbbs-in-nepal"> <span>MBBS In Nepal</span></a>
            {activeSubMenu === "nepal" ? <FaMinus /> : <FaPlus />}
          </div>
        }
        onOpenChange={() => handlesubToggle("nepal")}
        suffix={<span className="badge yellow"></span>}
        open={activeSubMenu === "nepal"} 
     // Opens only if this is the active menu
      >
            {universities.Nepal.map((university, index) => (




                // <Link  key={index} onClick={() => handleToggle()} to={`/university/${university.slug}`}>
                  
                //   <MenuItem className="sub-menu">- {university.name}  </MenuItem>
                // </Link>

                <a key={index} onClick={() => handleToggle()} href={`/university/${university.slug}`} >
<MenuItem className="sub-menu">- {university.name}  </MenuItem>
                </a>

              ))}

            </SubMenu>

            <SubMenu
        title={
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
           <a href="mbbs-in-philippines"> <span>MBBS In Philippines</span></a>
            {activeSubMenu === "philippines" ? <FaMinus /> : <FaPlus />}
          </div>
        }
        onOpenChange={() => handlesubToggle("philippines")}
        suffix={<span className="badge yellow"></span>}
        open={activeSubMenu === "philippines"} 
     // Opens only if this is the active menu
      >
           



                  <a href="/mbbs-in-brokenshire-college-of-medicine-philippines">
                  <MenuItem className="sub-menu">- MBBS in Brokenshire College of Medicine Philippines</MenuItem>
                </a>

                <Link  to={`/mbbs-in-our-lady-of-fatima-university-philippines`}>
                  <MenuItem className="sub-menu">- MBBS in Our Lady of Fatima University, Philippines</MenuItem>
                </Link>

                <Link  to={`/mbbs-in-university-of-perpetual-help-system-dalta-philippines`}>
                  <MenuItem className="sub-menu">- MBBS in University of Perpetual Help System Dalta Philippines</MenuItem>
                </Link>
            

            </SubMenu>


            <SubMenu
        title={
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
           <a href="mbbs-in-kyrgyzstan"> <span>MBBS In Kyrgyzstan</span></a>
            {activeSubMenu === "kyrgyzstan" ? <FaMinus /> : <FaPlus />}
          </div>
        }
        onOpenChange={() => handlesubToggle("kyrgyzstan")}
        suffix={<span className="badge yellow"></span>}
        open={activeSubMenu === "kyrgyzstan"} 
     // Opens only if this is the active menu
      >
           


                <Link  to={`/mbbs-in-osh-state-medical-university-kyrgyzstan`}>
                  <MenuItem className="sub-menu">- MBBS in OSH State Medical University Kyrgyzstan</MenuItem>
                </Link>

                <Link  to={`/mbbs-in-jalalabad-state-university-kyrgyzstan`}>
                  <MenuItem className="sub-menu">- MBBS in Jalal-Abad State University Kyrgyzstan</MenuItem>
                </Link>

               

            </SubMenu>


            <SubMenu
        title={
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
           <a href="/mbbs-in-china" ><span>MBBS In China</span></a>
            {activeSubMenu === "china" ? <FaMinus /> : <FaPlus />}
          </div>
        }
        onOpenChange={() => handlesubToggle("china")}
        suffix={<span className="badge yellow"></span>}
        open={activeSubMenu === "china"} 
     // Opens only if this is the active menu
      >
           


                <Link  to={`/mbbs-in-peking-university-health-science-center-china`}>
                  <MenuItem className="sub-menu">- MBBS in Peking University Health Science Center China</MenuItem>
                </Link>

                <Link  to={`/mbbs-in-fudan-university-shanghai-medical-college-china`}>
                  <MenuItem className="sub-menu">- MBBS in Fudan University Shanghai Medical College China</MenuItem>
                </Link>

                <Link  to={`/mbbs-in-zhejiang-university-school-of-medicine-china`}>
                  <MenuItem className="sub-menu">- MBBS in Zhejiang University School of Medicine, China</MenuItem>
                </Link>

               

            </SubMenu>


            <SubMenu
        title={
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
           <a href="mbbs-in-bangladesh"> <span>MBBS In Bangladesh</span></a>
            {activeSubMenu === "bangladesh" ? <FaMinus /> : <FaPlus />}
          </div>
        }
        onOpenChange={() => handlesubToggle("bangladesh")}
        suffix={<span className="badge yellow"></span>}
        open={activeSubMenu === "bangladesh"} 
     // Opens only if this is the active menu
      >
           


                <Link  to={`/mbbs-in-dhaka-national-medical-college-bangladesh`}>
                  <MenuItem className="sub-menu">- MBBS in Dhaka National Medical College Bangladesh</MenuItem>
                </Link>

                <Link  to={`/mbbs-in-eastern-medical-college-bangladesh`}>
                  <MenuItem className="sub-menu">- MBBS in Eastern Medical College Bangladesh</MenuItem>
                </Link>

                <Link  to={`/mbbs-in-khwaja-yunus-ali-medical-college-bangladesh`}>
                  <MenuItem className="sub-menu">- MBBS in Khwaja Yunus Ali Medical College, Bangladesh</MenuItem>
                </Link>

                <Link  to={`/mbbs-in-holy-family-red-crescent-medical-college-bangladesh`}>
                  <MenuItem className="sub-menu">- MBBS In Holy Family Red Crescent Medical College Bangladesh</MenuItem>
                </Link>

               

            </SubMenu>

            <SubMenu
        title={
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <a href="/mbbs-in-kazakhstan"><span>MBBS In Kazakhstan</span></a>
            {activeSubMenu === "kazakhstan" ? <FaMinus /> : <FaPlus />}
          </div>
        }
        onOpenChange={() => handlesubToggle("kazakhstan")}
        suffix={<span className="badge yellow"></span>}
        open={activeSubMenu === "kazakhstan"} 
     // Opens only if this is the active menu
      >
           


                <Link  to={`/mbbs-in-south-kazakhstan-medical-academy-kazakhstan`}>
                  <MenuItem className="sub-menu">- MBBS in South Kazakhstan Medical Academy Kazakhstan</MenuItem>
                </Link>

                <Link  to={`/mbbs-in-eastern-medical-college-bangladesh`}>
                  <MenuItem className="sub-menu">- MBBS in Eastern Medical College Bangladesh</MenuItem>
                </Link>

                <Link  to={`/mbbs-in-kazakh-national-medical-university-kazakhstan`}>
                  <MenuItem className="sub-menu">- MBBS in Kazakh National Medical University, Kazakhstan</MenuItem>
                </Link>

                <Link  to={`/mbbs-in-kokshetau-state-university-kazakhstan`}>
                  <MenuItem className="sub-menu">- MBBS in Kokshetau State University, Kazakhstan</MenuItem>
                </Link>

               

            </SubMenu>

            
            <Link onClick={() => handleToggle()} to={"/mbbs-in-uzbekistan"} > <MenuItem><span style={{ color: "#1d7dd6"}}>MBBS in Uzbekistan</span></MenuItem></Link>

            <Link onClick={() => handleToggle()} to={"/mbbs-in-georgia"}> <MenuItem ><span style={{ color: "#1d7dd6"}}>MBBS In Georgia</span></MenuItem></Link>


            

           
          </SubMenu>
          <Link onClick={() => handleToggle()} to={"/blogs"}> <MenuItem >Blogs</MenuItem></Link>

          <Link onClick={() => handleToggle()} to={"/contact-us"}> <MenuItem > Contact Us</MenuItem></Link>

        </Menu>
      </SidebarContent>

      <SidebarFooter>
        <div
          className="sidebar-btn-wrapper"
          style={{
            padding: "20px 24px",
          }}
        >
          <Link to="/contact-us" className="sidebar-btn" rel="noopener noreferrer">
            <span
              style={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
            >
              {/* <Button Button variant="contained" color="secondary">
                <FaHeadphonesAlt className="svgwhite" /> &nbsp; Get Support
              </Button> */}
            </span>
          </Link>
        </div>
      </SidebarFooter>
    </ProSidebar>
  );
};

export default Aside;
