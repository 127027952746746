import { React, useEffect, useState } from "react";

import { Bs1CircleFill, Bs2CircleFill, Bs3CircleFill, Bs4CircleFill, Bs5CircleFill, Bs6CircleFill, Bs7CircleFill, Bs8CircleFill, Bs9CircleFill } from "react-icons/bs";
import Admissionimg from '../../img/admission.svg';
import { API_URL } from '../../setting/apiConfig';


const AIUniversityKazakhstan = () => {
  const options = {
    loop: true,
    margin: 10,
    items: 1,
    autoplay: true,
    responsive: {
      0: {
        items: 1,
        nav: true,

      },
      600: {
        items: 3,
      },
      1000: {
        items: 4,
      },
    },
  };
  const [universities, setUniversities] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    fetch(`${API_URL}university-details`)
      .then((response) => response.json())
      .then((data) => {
        // Assuming 'results' contains the array of universities
        setUniversities(data);
        setIsLoading(false);
  
      })
      .catch((error) => {
        setIsLoading(false);
        console.error('Error fetching data:', error);
      });
  }, []);
  return (
    <>



      {/* section1 */}

      <div className='abt-sectionn pt-5 pb-5'>


        <div className='container'>

          <h1>MBBS in Kazakh National Medical University, Kazakhstan</h1>

          <div className='bds'></div>
          <div id='brdrs'></div>

          {/* <p>It is a long established fact that a reader.</p> */}


        </div>


      </div>




      <div className='container abt-2 mt-5 mb-5'>


        <p className='mb-4'>Welcome to Nayee Disha, your trusted companion on the journey to a remarkable medical education abroad! We are thrilled to present to you Kazakh National Medical University, a prestigious institution nestled in the heart of Kazakhstan. For Indian students aspiring to pursue their MBBS dreams, Kazakh National Medical University offers an exceptional opportunity to embark on a transformative academic voyage. With its unwavering commitment to excellence, renowned faculty, and cutting-edge facilities, this esteemed university stands as a gateway to your aspirations. Join us as we delve into the captivating world of MBBS education at Kazakh National Medical University, where you can acquire the knowledge, skills, and global exposure needed to become a compassionate and skilled medical professional.</p>

        <hr></hr>
        <div className='py-3 rounded'>

          <div className='container mt-3'>
            <h3 className='border_before'>Advantages of MBBS in Kazakh National Medical University, Kazakhstan</h3>
            <div className='row'>
              <div className='col-md-3 mt-4 '>
                <div className='bg-coloringrideant card-height  p-3 rounded'>
                  <b className='f-19'>Global Recognition</b>
                  <p className='f-17'>Acclaimed Degrees Opening the International Doors and Precious Chances</p>
                </div>

              </div>
              <div className='col-md-3 mt-4 '>
                <div className='bg-coloringrideant card-height p-3 rounded'>
                  <b className='f-19'>Affordable Education</b>
                  <p className='f-17'>Cost-Effective MBBS Programs without Compromising Quality and Rigour</p>
                </div>

              </div>
              <div className='col-md-3 mt-4 '>
                <div className='bg-coloringrideant card-height p-3 rounded'>
                  <b className='f-19'>Experienced Faculty</b>
                  <p className='f-17'>Renowned Professors Ensuring Comprehensive Medical Education Is Provided</p>
                </div>

              </div>
              <div className='col-md-3 mt-4 '>
                <div className='bg-coloringrideant card-height p-3 rounded'>
                  <b className='f-19'>Cultural Diversity</b>
                  <p className='f-17'>Exposure to Multicultural Environment Enhancing Global Perspective and Exposure</p>
                </div>

              </div>
            </div>
          </div>

          <div className='container mb-4 mt-3'>
            <div className='row'>
              <div className='col-md-3 mt-4 '>
                <div className='bg-coloringrideant card-height p-3 rounded'>



                  <b className='f-19'>Advanced Infrastructure</b>
                  <p className='f-17'>State-of-the-Art Facilities Are Provided Cutting-Edge Learning Opportunities</p>
                </div>

              </div>
              <div className='col-md-3 mt-4 '>
                <div className='bg-coloringrideant card-height p-3 rounded'>
                  <b className='f-19'>High-Quality Teaching</b>
                  <p className='f-17'>Rigorous Curriculum and Practical Training for Competent Medical Professionals</p>
                </div>

              </div>
              <div className='col-md-3 mt-4 '>
                <div className='bg-coloringrideant card-height p-3 rounded'>
                  <b className='f-19'>English-Medium</b>
                  <p className='f-17'>Language Barrier-Free Education Facilitating Easy Learning and Communication</p>
                </div>

              </div>
              <div className='col-md-3 mt-4 '>
                <div className='bg-coloringrideant card-height p-3 rounded'>
                  <b className='f-19'>Career Opportunities</b>
                  <p className='f-17'>Seamless Pathway to Explore Employment Options in Russia and Beyond.</p>
                </div>

              </div>
            </div>
          </div>
        </div>



        <hr></hr>




      </div>
      <div className='container'>
        <h3 className='border_before'>Facts about MBBS in Kazakh National Medical University, Kazakhstan</h3>
        <div className='row'>
          <div className='col-md-12'>
            <ul className='list-none mt-2'>
              <li className='mt-1 '><Bs1CircleFill />&nbsp; Global Recognition: Kazakh National Medical University is recognized by international medical bodies, ensuring the acceptance of the MBBS degree worldwide, including by the Medical Council of India (MCI).</li>
              <li className='mt-2 '> <Bs2CircleFill /> &nbsp; English-Medium Program: The MBBS program at Kazakh National Medical University is conducted in English, providing an inclusive learning environment for international students, including those from India.</li>
              <li className='mt-2 '> <Bs3CircleFill />&nbsp; Well-Established Reputation: The university has a long-standing reputation for excellence in medical education and research, attracting students from around the globe.</li>
              <li className='mt-2 '> <Bs4CircleFill /> &nbsp; State-of-the-Art Facilities: Kazakh National Medical University is equipped with modern facilities, advanced laboratories, and sophisticated medical equipment, providing students with an enriched learning experience.</li>
              <li className='mt-2 '> <Bs5CircleFill /> &nbsp; Experienced Faculty: The university boasts a highly qualified faculty comprising experts in various medical disciplines, offering comprehensive guidance and mentorship to students.</li>
              <li className='mt-2 '> <Bs6CircleFill /> &nbsp; Clinical Training Opportunities: Students have access to well-equipped hospitals and clinics affiliated with the university, providing ample opportunities for clinical training and exposure to diverse medical cases.</li>
              <li className='mt-2 '><Bs7CircleFill /> &nbsp; International Student Community: Kazakh National Medical University attracts a diverse community of students from different countries, fostering a multicultural environment and promoting global friendships.</li>
              <li className='mt-2 '><Bs8CircleFill /> &nbsp; Affordable Tuition Fees: The university offers a cost-effective MBBS program, making it an attractive option for Indian students seeking quality education at an affordable cost.</li>
              <li className='mt-2 '><Bs9CircleFill /> &nbsp; Research and Innovation: The university encourages research activities and provides platforms for students to engage in medical research, contributing to advancements in the field of medicine.</li>

            </ul>
          </div>

        </div>
      </div>
      <hr></hr>
      <div className='container'>
        <h3 className='border_before'>Admission Process and Documents Needed</h3>
        <div className='row'>
          <div className='col-md-12'>

          </div>

        </div>
      </div>

      <div className='container'>

        <img src={Admissionimg} alt="img" loading="lazy" className='w-100 mt-3' />
      </div>

      <br></br>
  
    </>
  );
};

export default AIUniversityKazakhstan;