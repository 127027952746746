import React, { useState, useRef, useEffect } from "react";
import { SlCallOut } from "react-icons/sl";
import { IoChatboxEllipsesOutline } from "react-icons/io5";
import { IoIosCloseCircleOutline } from "react-icons/io";
import PhoneInput from 'react-phone-input-2'
import InputGroup from 'react-bootstrap/InputGroup';
import Swal from 'sweetalert2';
import { useHistory } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import { API_URL } from '../setting/apiConfig';

const CallBackRequest = () => {
    const formRef = useRef(null);
    const [isHovered, setIsHovered] = useState(false);
    const [newIsHovered, setNewIsHovered] = useState(false);
    const [newIsDropdownHovered, setNewIsDropdownHovered] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isloading, setIsLoading] = useState(true);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [successMessage, setSuccessMessage] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [universities, setUniversities] = useState([]);
    const history = useHistory();
    const submitForm = async (e) => {
        e.preventDefault();
        const data = new FormData(e.target);
        const formData = Object.fromEntries(data.entries());

        try {
            setLoading(true);

            const response = await fetch(`${API_URL}request-callback`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            const responseData = await response.json();

            if (!response.ok) {
                // Extract the detailed error message if available
                const errorMessage =
                    responseData.error?.phone_number?.[0] || responseData.error || 'Failed to add Sent Message';
                throw new Error(errorMessage);
            }

            setLoading(false);
            setSuccessMessage(responseData.message);
            setErrorMessage(null);
            history.push('/');
            formRef.current.reset();

            // Show success message using SweetAlert2
            Swal.fire({
                title: 'Success!',
                text: responseData.message,
                icon: 'success',
            });

            // Hide success message after 5 seconds
            setTimeout(() => {
                setSuccessMessage(null);
            }, 5000);

            setPhoneNumber(''); // Clear phone input
            formRef.current.reset(); // Reset the entire form
        } catch (error) {
            console.error(error);
            setLoading(false);
            setSuccessMessage(null);
            setErrorMessage(error.message);

            // Show error message using SweetAlert2
            Swal.fire({
                title: 'Error!',
                text: error.message, // Show the extracted error message
                icon: 'error',
                showConfirmButton: false,
                timer: 1500,
            });
        }
    };

    const iconStyle = {
        width: "29px",
        height: "29px",
        color:"#fff",
        fill:"#fff",
        transition: "transform 0.3s ease-in-out",
        transform: isHovered ? "rotate(360deg)" : "rotate(0deg)",
    };
    const handleNewMouseEnter = () => {
        setNewIsHovered(true);
    };

    const handleNewMouseLeave = () => {
        setNewIsHovered(false);
    };
    return (

        <>
            <div className="modal fade" id="exampleModalCentercall" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <p className="modal-title anifont h3" id="exampleModalLongTitle">Request a call back</p>
                            <button type="button" className="close btn btn-danger text-white" data-dismiss="modal" aria-label="Close">

                                <IoIosCloseCircleOutline className="text-white requist" style={iconStyle}
                                    onMouseEnter={() => setIsHovered(true)}
                                    onMouseLeave={() => setIsHovered(false)} />
                            </button>
                        </div>
                        <div className="modal-body">
                            <form ref={formRef} onSubmit={submitForm} className="mx-3">
                                <div id="form" className='pt-3'>



                                    <div className="form-group">
                                        <InputGroup className="mb-3" type="text">
                                            {/* <InputGroup.Text id="basic-addon1">@</InputGroup.Text> */}
                                            <Form.Control className='p-2'
                                                placeholder="Full Name"
                                                aria-label="Username"
                                                aria-describedby="basic-addon1"
                                                name='name'
                                                required
                                            />
                                        </InputGroup>


                                    </div>







                                    <div className="form-group mt-3  ph-forms  ">
                                        <span className="wpcf7-form-control-wrap your-name ">
                                            {/* <input name='phone_number' className='p-2' placeholder=" Phone Number" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" type="number" maxlength="10" required=""

                      /> */}
                                            <PhoneInput inputProps={{
                                                name: 'phone_number', required: true, autoFocus: true
                                            }}
                                                country={'in'}
                                                value={phoneNumber}
                                                onChange={(phone) => setPhoneNumber(phone)}

                                            />


                                        </span>
                                    </div>





                                    <div className="form-group mt-3  ph-forms  ">
                                        <span className="wpcf7-form-control-wrap your-name ">

                                            <textarea className="p-2" name='message' placeholder=' Message' rows={5} cols={66} />



                                        </span></div>


                                </div>





                                <div className='mt-3 fr-but'>
                                    <input type="submit" value='Submit' className="btn btn-primary btn-accent" />
                                </div>

                            </form>
                            <br></br>
                        </div>

                    </div>
                </div>
            </div>
            <div className="container mt-3 mb-3">
                <div className="card shadow mt-5 lightGreenBg border-0">
                    <div className="card-body p-3">
                        <div className="row align-items-center justify-content-md-around justify-content-center">
                            <div className="col-md-8 col-12 d-flex align-items-md-center">
                                <IoChatboxEllipsesOutline className="h1 text-primary_update me-md-5 me-2 pt-md-0 pt-3" />
                                <div>
                                    <p className="text-primary_update h4 fw-bold">Tap into expert advice, fast and hassle-free?</p>
                                    <p className="text-primary_update mb-md-0">Professional guidance at your fingertips, anytime.</p>
                                </div>
                            </div>

                            <div  className="col-md-4 col-12 text-center"  >
                                <a  href="tel:+918860031900"
                                    className="btn btn-success wave_talk" style={{ background: '#c3123c', border: '1px solid #E91E63' }}

                                >
                                    Call Us : +91 8860031900 <SlCallOut className="fa-shake ms-2" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <br></br>
            </div>
        </>





    );
};

export default CallBackRequest;
