import { createAsyncThunk } from '@reduxjs/toolkit';
import {API_URL} from '../setting/apiConfig'
// This is the async thunk action for fetching blog details
export const fetchBlogDetails = createAsyncThunk(`${API_URL}blog-details`, async () => {
    const response = await fetch(`${API_URL}blog-details`);
    if (!response.ok) {
        throw new Error('Failed to fetch blog details');
    }
    const data = await response.json();
    return data;
});
