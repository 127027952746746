import React, { useEffect, useState } from 'react';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Accordion from 'react-bootstrap/Accordion';
import searchicon from '../img/search-icon.png';
import { Spinner, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { TfiViewListAlt, TfiViewGrid } from "react-icons/tfi";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import Pagination from 'react-bootstrap/Pagination';
import { GrPowerReset } from "react-icons/gr";
import Badge from '@mui/material/Badge';
import { BsFilterCircleFill } from "react-icons/bs";
import russia from "../img/Flag_of_Russia.png";
import nepal from "../img/images_nepal.jpeg";
import India from "../img/Flag_of_India.png";
import { IoIosArrowRoundForward } from "react-icons/io";
import { useSelector, useDispatch } from 'react-redux';

import { fetchActiveData } from '../../store/navigationDataSlice';


import Card from 'react-bootstrap/Card';
const Universitylist = ({ id }) => {
   
    const dispatch = useDispatch();

    const { list, loading, error } = useSelector((state) => state.navigationData);
    const truncateContent = (content, wordLimit) => {
        if (!content) return 'No content';
      
        // Use DOMParser to convert HTML to plain text
        const doc = new DOMParser().parseFromString(content, 'text/html');
        const textContent = doc.body.textContent || doc.body.innerText; // Get the plain text
      
        // Split text into words
        const words = textContent.split(' ');
      
        // Check if the content exceeds the word limit
        if (words.length > wordLimit) {
          return words.slice(0, wordLimit).join(' ') + '...';
        }
      
        return textContent; // Return the plain text content if it's within the limit
      };
      

    const textContent = 8; // Set 
    useEffect(() => {
        dispatch(fetchActiveData()); // Fetch active universities on component mount
      }, [dispatch]);
    
      if (loading) return (
        <div>
            <Skeleton count={5} height={30} />
            <Skeleton count={3} height={50} />
        </div>
    );
    
    
      if (error) {
        return <p>Error: {error}</p>;
      }
      if (list.length === 0) {
        return <div>
        <Skeleton count={5} height={10} />
        <Skeleton count={3} height={10} />
    </div>;
      }

    return (
        <>
   

            <div className='abt-section-news pt-5 pb-5 text-center'>
                <div className='container'>
                    <h3 className="text-white"> Study Abroad Countries</h3>
                    {/* <h3  className="we-provide mt-4 text-white">Study Abroad Countries</h3> */}
                    <div className='bds-news'></div>
                    <div id='brdrs'></div>
                </div>
                </div>

                <div className='container borderd pt-3'>
                <Breadcrumb>
                    <Breadcrumb.Item href="#" id="crumb">Home</Breadcrumb.Item>
                    <Breadcrumb.Item href="" id="crumb">Study Abroad</Breadcrumb.Item>
                </Breadcrumb>
                <hr></hr>
            </div>
           

            <div className='container mt-5'>
                <div className='row'>
                {list.map((data) => (
                <div className="col-md-3 mt-3 mb-5">
      
         <Link to={data.slug} className="text-dark"><Card>
            <Card.Img variant="top" src={data.thumbnail || "#"} className="img-fluid" loading="lazy" style={{width: '100',height: '150px',objectFit: 'cover', // Ensures the image fills the area without distortionborderRadius: '8px', // Optional: Adds rounded corners
}}  />
            <Card.Body>
              <Card.Title>{data.title || 'No Title'}</Card.Title>
              <Card.Text>{truncateContent(data.content, textContent)}</Card.Text>
              
              <Button variant="outline-success" className="btn btn-outline-primary">Read More</Button>
            </Card.Body>
          </Card>
          </Link>
      </div>
            ))}
                </div>
            </div>
            <br></br>

        
        </>
    );
};

export default Universitylist;
