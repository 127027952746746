import { createSlice } from '@reduxjs/toolkit';
import { fetchUniversityDetails } from './universityActions';

const universitySlice = createSlice({
    name: 'university',
    initialState: {},
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchUniversityDetails.fulfilled, (state, action) => {
            const { uid, data } = action.payload;
            state[uid] = data; // Cache the data
        });
    }
});

export default universitySlice.reducer;
