import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Notfount from '../../img/404.svg';
import { BASE_URL } from '../../setting/apiConfig';
const NotFoundPage = () => {
  return (

    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Page Not Found </title>
        <meta name="description" content="Page Not Found"></meta>
        <meta name="keywords"
          content=" Page Not Found " />

        <link rel="canonical" href={`${BASE_URL}`} />
      </Helmet>
      <div className='py-5 px-5 text-center'>

        <img src={Notfount} alt="Not Found" loading="lazy" width="300" className="mt-5" />
        <div className='text-center'>
          <Link
            to={
              "/"
            }
          >
            <button type="button" className="btn btn-outline-success mt-3">Home</button>
          </Link> &nbsp;

          <Link
            to={
              "/mbbs-in-abroad"
            }
          >
            <button type="button" className="btn btn-outline-success mt-3">Mbbs In Abroad</button></Link> &nbsp;  &nbsp;

          <Link
            to={
              "/mbbs-in-india"
            }
          >
            <button type="button" className="btn btn-outline-success mt-3">Mbbs In India</button></Link>
        </div>
        <h1 className='d-none'>Sorry, the page you were looking for could not be found!</h1>
      </div>
    </>


  );
};

export default NotFoundPage;