import { React, useEffect, useState } from "react";
import { Bs1CircleFill, Bs2CircleFill, Bs3CircleFill, Bs4CircleFill, Bs5CircleFill, Bs6CircleFill, Bs7CircleFill, Bs8CircleFill, Bs9CircleFill } from "react-icons/bs";
import Admissionimg from '../../img/admission.svg';
import TabAdmissionimg from '../../img/tabmobile.svg';
import { API_URL } from '../../setting/apiConfig';

const Universityofperpetual = () => {
  const options = {
    loop: true,
    margin: 10,
    items: 1,
    autoplay: true,
    responsive: {
      0: {
        items: 1,
        nav: true,

      },
      600: {
        items: 3,
      },
      1000: {
        items: 4,
      },
    },
  };
  const [universities, setUniversities] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    fetch(`${API_URL}/university-details`)
      .then((response) => response.json())
      .then((data) => {
        // Assuming 'results' contains the array of universities
        setUniversities(data);
        setIsLoading(false);

      })
      .catch((error) => {
        setIsLoading(false);
        console.error('Error fetching data:', error);
      });
  }, []);
  return (
    <>



      {/* section1 */}

      <div className='abt-sectionn pt-5 pb-5'>


        <div className='container'>

          <h1>MBBS in University of Perpetual Help System Dalta, Philippines</h1>

          <div className='bds'></div>
          <div id='brdrs'></div>

          {/* <p>It is a long established fact that a reader.</p> */}


        </div>


      </div>




      <div className='container abt-2 mt-5 mb-5'>


        <p className='mb-4'>Welcome to Nayee Disha, your trusted partner in shaping your dreams of pursuing a distinguished medical education abroad! We are thrilled to introduce you to the University of Perpetual Help System Dalta, a prestigious institution nestled in the captivating landscapes of the Philippines. For Indian students aspiring to become exceptional medical professionals, the University of Perpetual Help System Dalta offers an extraordinary opportunity to embark on a transformative educational journey. With its unwavering commitment to academic excellence, state-of-the-art facilities, and a nurturing learning environment, this esteemed university stands as a beacon of success. Join us as we explore the captivating world of MBBS education at the University of Perpetual Help System Dalta, where limitless possibilities await, and your ambitions to make a difference in the world of medicine can thrive.</p>

        <hr></hr>
        <div className='py-3 rounded'>

          <div className='container mt-3'>
            <h3 className='border_before'>Advantages of MBBS in Perpetual Help System Dalta, Philippines</h3>
            <div className='row'>
              <div className='col-md-3 mt-4 '>
                <div className='bg-coloringrideant card-height  p-3 rounded'>
                  <b className='f-19'>Global Recognition</b>
                  <p className='f-17'>Acclaimed Degrees Opening the International Doors and Precious Chances</p>
                </div>

              </div>
              <div className='col-md-3 mt-4 '>
                <div className='bg-coloringrideant card-height p-3 rounded'>
                  <b className='f-19'>Affordable Education</b>
                  <p className='f-17'>Cost-Effective MBBS Programs without Compromising Quality and Rigour</p>
                </div>

              </div>
              <div className='col-md-3 mt-4 '>
                <div className='bg-coloringrideant card-height p-3 rounded'>
                  <b className='f-19'>Experienced Faculty</b>
                  <p className='f-17'>Renowned Professors Ensuring Comprehensive Medical Education Is Provided</p>
                </div>

              </div>
              <div className='col-md-3 mt-4 '>
                <div className='bg-coloringrideant card-height p-3 rounded'>
                  <b className='f-19'>Cultural Diversity</b>
                  <p className='f-17'>Exposure to Multicultural Environment Enhancing Global Perspective and Exposure</p>
                </div>

              </div>
            </div>
          </div>

          <div className='container mb-4 mt-3'>
            <div className='row'>
              <div className='col-md-3 mt-4 '>
                <div className='bg-coloringrideant card-height p-3 rounded'>



                  <b className='f-19'>Advanced Infrastructure</b>
                  <p className='f-17'>State-of-the-Art Facilities Are Provided Cutting-Edge Learning Opportunities</p>
                </div>

              </div>
              <div className='col-md-3 mt-4 '>
                <div className='bg-coloringrideant card-height p-3 rounded'>
                  <b className='f-19'>High-Quality Teaching</b>
                  <p className='f-17'>Rigorous Curriculum and Practical Training for Competent Medical Professionals</p>
                </div>

              </div>
              <div className='col-md-3 mt-4 '>
                <div className='bg-coloringrideant card-height p-3 rounded'>
                  <b className='f-19'>English-Medium</b>
                  <p className='f-17'>Language Barrier-Free Education Facilitating Easy Learning and Communication</p>
                </div>

              </div>
              <div className='col-md-3 mt-4 '>
                <div className='bg-coloringrideant card-height p-3 rounded'>
                  <b className='f-19'>Career Opportunities</b>
                  <p className='f-17'>Seamless Pathway to Explore Employment Options in Russia and Beyond.</p>
                </div>

              </div>
            </div>
          </div>
        </div>



        <hr></hr>




      </div>
      <div className='container'>
        <h3 className='border_before'>Facts about Perpetual Help System Dalta, Philippines</h3>
        <div className='row'>
          <div className='col-md-12'>
            <ul className='list-none mt-2'>
              <li className='mt-1 '><Bs1CircleFill />&nbsp; Global Recognition: The University of Perpetual Help System Dalta enjoys global recognition and is listed in international medical directories, ensuring worldwide acceptance of your MBBS degree.</li>
              <li className='mt-2 '> <Bs2CircleFill /> &nbsp; Excellent Academic Standards: The university maintains high academic standards, delivering a comprehensive MBBS program that equips students with the necessary knowledge and skills for a successful medical career.</li>
              <li className='mt-2 '> <Bs3CircleFill />&nbsp; Experienced Faculty: The university boasts a team of experienced and dedicated faculty members who provide quality education, mentorship, and support to students throughout their academic journey.</li>
              <li className='mt-2 '> <Bs4CircleFill /> &nbsp; Extensive Clinical Training: The university offers extensive clinical training opportunities in leading hospitals and healthcare facilities, allowing students to gain hands-on experience and develop clinical competence.</li>
              <li className='mt-2 '> <Bs5CircleFill /> &nbsp; State-of-the-Art Facilities: The university is equipped with state-of-the-art facilities, including modern laboratories, libraries, and research centers, facilitating a conducive learning environment.</li>
              <li className='mt-2 '> <Bs6CircleFill /> &nbsp; English-Medium Program: The MBBS program at the University of Perpetual Help System Dalta is conducted in English, eliminating language barriers and ensuring effective communication and understanding.</li>
              <li className='mt-2 '><Bs7CircleFill /> &nbsp; Affordable Tuition Fees: The university offers competitive tuition fees, making quality medical education accessible and affordable for Indian students.</li>
              <li className='mt-2 '><Bs8CircleFill /> &nbsp; Multicultural Environment: Studying at the University of Perpetual Help System Dalta provides exposure to a multicultural environment, fostering cultural diversity and global networking opportunities.</li>
              <li className='mt-2 '><Bs9CircleFill /> &nbsp; Support Services: The university provides comprehensive support services, including accommodation assistance, visa guidance, and student support programs, ensuring a smooth transition and a fulfilling educational experience.</li>

            </ul>
          </div>

        </div>
      </div>
      <hr></hr>
      <div className='container'>
        <h3 className='border_before'>Admission Process and Documents Needed</h3>
        <div className='row'>
          <div className='col-md-12'>

          </div>

        </div>
      </div>

      <div className='container'>

      <picture data-bs-toggle="modal"
data-bs-target="#exampleModal"
data-bs-whatever="@mdo">
{/* Mobile Image */}
<source media="(max-width: 576px)" loading="lazy" srcSet={TabAdmissionimg} />
{/* Tablet Image */}
<source media="(max-width: 992px)" loading="lazy" srcSet={TabAdmissionimg} />
{/* Desktop Image */}
<img
  className='w-100 mt-3'
   src={Admissionimg} // Fallback image for larger screens
   alt="Responsive slide"
   loading="lazy"
/>
</picture>
      </div>

      <br></br>
    
    </>
  );
};

export default Universityofperpetual;




