import { createSlice } from '@reduxjs/toolkit';
import { fetchBlogDetails } from './blogActions'; // Import the async thunk

const initialState = {
    isLoading: false,
    data: [],
    error: null,
};

const blogSlice = createSlice({
    name: 'blog',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchBlogDetails.pending, (state) => {
                state.isLoading = true; // Set loading state when the request starts
            })
            .addCase(fetchBlogDetails.fulfilled, (state, action) => {
                state.isLoading = false; // Set loading state to false when data is fetched
                state.data = action.payload; // Store the fetched data
            })
            .addCase(fetchBlogDetails.rejected, (state, action) => {
                state.isLoading = false; // Set loading state to false when an error occurs
                state.error = action.error.message; // Store the error message
            });
    },
});

export default blogSlice.reducer;
