import React, { useState, useRef, useEffect } from "react";
import "bootstrap/dist/js/bootstrap.js";
import { FaBars, FaLock } from "react-icons/fa";
import { Link } from "react-router-dom";
import nayeedishalogosfin from "../img/nayeedishalogos-fin.svg";
import topi1 from "./lets-icons_send-fill.svg";
import topi3 from "./lets-icons_send-fill.svg";
import facebook from "./facebook.svg";
import { FaXTwitter } from "react-icons/fa6";
import linkedin from "./linkedin.svg";
import instagram from "./instagram.svg";
import whatsapp from "./whatsapp.svg";
import { useHistory } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2'
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import { AiFillCaretRight } from "react-icons/ai";
import Swal from 'sweetalert2';
import { useLocation } from 'react-router-dom';
import { CiYoutube } from "react-icons/ci";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { FaSearch } from "react-icons/fa";
import Modal from "bootstrap/js/src/modal";
import { fetchCourses } from '../store/actions/courseActions';
import { useDispatch } from "react-redux";
import { API_URL } from '../setting/apiConfig';

const Header = ({ isMobile, handleToggleSidebar }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [submenuOpen, setSubmenuOpen] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  const [russiaUniversities, setRussiaUniversities] = useState([]);
  const [nepalUniversities, setNepalUniversities] = useState([]);
  const location = useLocation();  // useLocation instead of global location
  const [courseData, setCourseData] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [universityList, setUniversityList] = useState([]); 
  const [universityindiaList, setUniversityindiaList] = useState([]); 

  
  // Extract course query parameter from the URL
  const params = new URLSearchParams(location.search);
  const course = params.get('course');
  const dispatch = useDispatch();
  const modalRef = useRef(null);

  useEffect(() => {
    if (modalRef.current) {
      // Initialize the modal
      new Modal(modalRef.current);
    }
  }, []);

  const openModal = () => {
    const modalInstance = Modal.getOrCreateInstance(modalRef.current);
    modalInstance.show();
  };

  const closeModal = () => {
    const modalInstance = Modal.getOrCreateInstance(modalRef.current);
    modalInstance.hide();
  };


  useEffect(() => {
    const cachedData = localStorage.getItem("universities");
    const cacheTime = localStorage.getItem("universities_cache_time");
    const cacheDuration = 5 * 60 * 1000; // 5 minutes

    if (cachedData && cacheTime && Date.now() - cacheTime < cacheDuration) {
      setUniversityList(JSON.parse(cachedData)); // Use cached data
    } else {
      fetch(`${API_URL}universityByTitle/?status=active`)
        .then((response) => response.json())
        .then((data) => {
          setUniversityList(data.data);
          localStorage.setItem("universities", JSON.stringify(data.data)); // Cache data
          localStorage.setItem("universities_cache_time", Date.now()); // Update timestamp
        })
        .catch((error) => console.error("Error fetching data:", error));
    }
  }, []);

// close
// for india 
useEffect(() => {
  const cachedData = localStorage.getItem("universitiesindia");
  const cacheTime = localStorage.getItem("universities_cache_time");
  const cacheDuration = 5 * 60 * 1000; // 5 minutes

  if (cachedData && cacheTime && Date.now() - cacheTime < cacheDuration) {
    setUniversityindiaList(JSON.parse(cachedData)); // Use cached data
  } else {
    fetch(`${API_URL}universityByTitle/list_india/?status=active`)
      .then((response) => response.json())
      .then((data) => {
        setUniversityindiaList(data.data);
        localStorage.setItem("universitiesindia", JSON.stringify(data.data)); // Cache data
        localStorage.setItem("universities_cache_time", Date.now()); // Update timestamp
      })
      .catch((error) => console.error("Error fetching data:", error));
  }
}, []);
// close
  // course filter
  
  const [filters, setFilters] = useState({
    country: '',
    state: '',
    city: '',
    fee: '',
    course: '',
  });

  const handleChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(fetchCourses(filters)); // Dispatch with dynamic filters
  };

// Close


  const handleDropdownToggle = () => {
    setDropdownOpen(!dropdownOpen);
  };
  const handleDropdownClose = () => {
    setDropdownOpen(false);
  };
  // Function to handle toggling the submenu
  const handleSubmenuToggle = () => {
    setSubmenuOpen(!submenuOpen);
  };

  const navigate = useLocation();


  const [isHovered, setIsHovered] = useState(false);
  const [isDropdownHovered, setIsDropdownHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleDropdownMouseEnter = () => {
    setIsDropdownHovered(true);
  };

  const handleDropdownMouseLeave = () => {
    setIsDropdownHovered(false);
  };

  const [newSubmenuOpen, setNewSubmenuOpen] = useState(false);
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [isloading, setIsLoading] = useState(true);
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [universities, setUniversities] = useState([]);
  const formRef = useRef(null);

  // show university according country
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const cachedData = localStorage.getItem('university-details');
      let universityData = cachedData ? JSON.parse(cachedData) : null;
  
      if (!universityData) {
        const response = await fetch(API_URL+ 'university-details');
        universityData = await response.json();
        // localStorage.setItem('university-details', JSON.stringify(universityData));
      }
  
      setRussiaUniversities(universityData.filter(u => u.country === 'Russia'));
      setNepalUniversities(universityData.filter(u => u.country === 'Nepal'));
      setIsLoading(false);
    };
  
    fetchData().catch(console.error);
  }, []);
  

  // Close

  useEffect(() => {
    if (successMessage) {
      Swal.fire({
        title: "Success!",
        text: successMessage,
        icon: "success"
      });
    }
  }, [successMessage]);

  useEffect(() => {
    if (errorMessage) {
      Swal.fire({
        title: "Error!",
        text: errorMessage,
        icon: "error"
      });
    }
  }, [errorMessage]);



  const submitForm = async (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const formData = Object.fromEntries(data.entries());

    try {
      setLoading(true);

      const response = await fetch(`${API_URL}request-callback`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      const responseData = await response.json();

      if (!response.ok) {
        // Extract the detailed error message if available
        const errorMessage =
          responseData.error?.phone_number?.[0] || responseData.error || 'Failed to add Sent Message';
        throw new Error(errorMessage);
      }

      setLoading(false);
      setSuccessMessage(responseData.message);
      setErrorMessage(null);
      history.push('/');
      formRef.current.reset();

      // Show success message using SweetAlert2
      Swal.fire({
        title: 'Success!',
        text: responseData.message,
        icon: 'success',
      });

      // Hide success message after 5 seconds
      setTimeout(() => {
        setSuccessMessage(null);
      }, 5000);

      setPhoneNumber(''); // Clear phone input
      formRef.current.reset(); // Reset the entire form
    } catch (error) {
      console.error(error);
      setLoading(false);
      setSuccessMessage(null);
      setErrorMessage(error.message);

      // Show error message using SweetAlert2
      Swal.fire({
        title: 'Error!',
        text: error.message, // Show the extracted error message
        icon: 'error',
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };


  const iconStyle = {
    width: "46px",
    height: "46px",
    transition: "transform 0.3s ease-in-out",
    transform: isHovered ? "rotate(360deg)" : "rotate(0deg)",
  };

  const excludedTitles = ["MBBS in Philippines", "MBBS in Russia", "MBBS in Nepal", "MBBS In Kyrgyzstan", "MBBS In China", "MBBS In Bangladesh", "MBBS In Kazakhstan", "MBBS in India", ""];


  const desktopmenu = () => {
    //



    //
    return (
      <div>
        {/* request callback */}
        <div ref={modalRef} className="modal fade" id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <p className="modal-title anifont" id="exampleModalLongTitle">Request a call back <br></br>
                  <span className="text-success f-14" style={{ fontWeight: 500 }}>Book your free counseling session today and take the first step toward your brighter future!</span></p>

                <button onClick={closeModal} type="button" className="close btn background-primary" data-dismiss="modal" aria-label="Close">

                  <IoIosCloseCircleOutline style={iconStyle}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)} />
                </button>
              </div>

              <div className="modal-body">
                <form ref={formRef} onSubmit={submitForm} className="mx-3" id="registration">
                  <div id="form" className='pt-3'>


                    <div className="form-group">
                      <Form.Label className='myinput'>Full Name <span className="spamred">*</span></Form.Label>

                      <InputGroup className="mb-3" type="text">
                        {/* <InputGroup.Text id="basic-addon1">@</InputGroup.Text> */}
                        <Form.Control className='p-2 f-14'
                          placeholder="  Name *"
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                          name='name'
                          required
                        />
                      </InputGroup>


                    </div>
                  
                  


                  <div className="form-group ph-forms  ">
                    <span className="wpcf7-form-control-wrap your-name ">
                      {/* <input name='phone_number' className='p-2' placeholder=" Phone Number" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" type="number" maxlength="10" required=""

                      /> */}
                      <Form.Label className='myinput'>Mobile Number <span className="spamred">*</span></Form.Label>

                      <PhoneInput inputProps={{
                        name: 'phone_number', required: true, autoFocus: true
                      }}
                        country={'in'}
                        value={phoneNumber}
                        onChange={(phone) => setPhoneNumber(phone)}

                      />


                    </span>



                  </div>















                  <div className="form-group mt-3  ph-forms  ">
                    <Form.Label className='myinput'>Message <span className="spamred">*</span></Form.Label>

                    <span className="wpcf7-form-control-wrap your-name ">

                      <textarea className="p-2" name='message' placeholder=' Message' rows={3} cols={20} />



                    </span></div>


              </div>




              <div className='text-center mt-3'>
                <p className='text-success f-14'><FaLock className='text-success' /> Your personal information is secure with us</p>
              </div>
              <div className='mt-3 fr-but'>
                <input type="submit" value='Submit' className="btn btn-primary btn-accent text-white py-3" />
              </div>

            </form>
            <br></br>
          </div>

        </div>
      </div>
        </div >

  {/* close */ }
{/* upper top header start */ }
<div className="upper-header">
  <div className="container">
    <div className="row">
      <div className="col-md-8">
        <div className="d-flex header-toppadding">

          <div
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
            data-bs-whatever="@mdo"
          > <p className="coursor">

              <img src={topi1} alt="Nayeedisha" loading="lazy" style={{ width: "" }} /> Apply
              Online
            </p>
          </div>
          &nbsp;&nbsp;&nbsp;

          <span onClick={() => setModalShow(true)}>


            <p onClick={openModal} data-toggle="modal" data-target="#exampleModalCenter" className="coursor">

              <img src={topi3} alt="Nayeedisha" loading="lazy" style={{ width: "" }} /> Request a
              call back
            </p>
          </span>
        </div>
      </div>
      <div className="col-md-4 text-end mt-2 ">
        <div>
          <a href="https://www.facebook.com/people/NayeeDisha-Study-Abroad/61559962673504/" target="_blank" rel="noopener"><img src={facebook} alt="Nayeedisha" style={{ width: "" }} /></a>
          &nbsp;&nbsp;&nbsp;
          <a href="https://x.com/NayeeInfo" target="_blank" rel="noopener"><FaXTwitter className="text-white" /></a>
          &nbsp;&nbsp;&nbsp;
          <a href="https://www.linkedin.com/company/nayeedisha/" target="_blank" rel="noopener">  <img src={linkedin} alt="Nayeedisha" style={{ width: "" }} /></a>
          &nbsp;&nbsp;&nbsp;
          <a href="https://wa.me/+918860031900" target="_blank" rel="noopener"><img src={whatsapp} alt="Nayeedisha" style={{ width: "" }} /></a>
          &nbsp;&nbsp;&nbsp;

          <a href="https://www.instagram.com/nayeedisha_official/?hl=en" target="_blank" rel="noopener noreferrer"><img src={instagram} alt="Nayeedisha" style={{ width: "" }} /></a> &nbsp;
          <a
            href="https://www.youtube.com/@nayeedisha"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CiYoutube style={{ fill: 'white', width: '22px', height: '22px' }} />
          </a>


        </div>
      </div>
    </div>
  </div>
</div>
{/*  */ }
{/* top header start */ }


<div className="top-header-2 pt-1">
  <div className="container">
    <div className="row">
      <div className="col-md-4 d-flex pt-2">
        <a className="navbar-brand" href="/">
          {/* <img src={nayeed} alt="NayeeDisha" style={{ width: "" }} /> */}
          <img
            src={nayeedishalogosfin}
            alt="logo"
            style={{ width: "200px" }}
          />
          <p className="pt-2 d-none" style={{
            fontSize: '15px',
            fontWeight: 500,
            color: '#000',
            fontFamily: 'fantasy'
          }}>Sapne aapke , disha humari</p>
        </a>
      </div>
      <div className="col-md-8 d-flex justify-content-end pt-3 pb-3">
        <div className=" d-flex ">
          <div className="pt-2 svgrs icon-width">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="23"
              height="29"
              fill="currentColor"
              class="bi bi-telephone"
              viewBox="0 0 16 16"
            >
              <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
            </svg>
            &nbsp;
          </div>
          <a href="tel:+91 8860031900"><div className="t-hc pt-1">
            <p className="p-0 m-0 callnow_newcolor"> Call Us Now</p>
            <p className="p-0"> +91 8860031900</p>
          </div>
          </a>
          <div className="firster"></div>
        </div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <div className=" d-flex">
          <div className="pt-2 svgrs2 icon-width">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="23"
              height="29"
              fill="currentColor"
              className="bi bi-whatsapp"
              viewBox="0 0 16 16"
            >
              <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" />
            </svg>
            &nbsp;
          </div>
          <a href="https://wa.me/+918860031900" target="_blank"><div className="t-hc pt-1">
            <p className="p-0 m-0 whatsapp_newcolor"> Whatsapp Us</p>
            <p className="p-0"> +91 8860031900</p>
          </div>
          </a>
        </div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <div className=" divider-crds-desk pt-1">
          <div
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
            data-bs-whatever="@mdo"
          >
            <span className="anibutton">
              Apply Now
              <svg fill="currentColor" viewBox="0 0 24 24" className="icon">
                <path clip-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm4.28 10.28a.75.75 0 000-1.06l-3-3a.75.75 0 10-1.06 1.06l1.72 1.72H8.25a.75.75 0 000 1.5h5.69l-1.72 1.72a.75.75 0 101.06 1.06l3-3z" fill-rule="evenodd"></path>
              </svg>
            </span>
            {/* <button type="button" className="btn wave">
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-headset"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 1a5 5 0 0 0-5 5v1h1a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V6a6 6 0 1 1 12 0v6a2.5 2.5 0 0 1-2.5 2.5H9.366a1 1 0 0 1-.866.5h-1a1 1 0 1 1 0-2h1a1 1 0 0 1 .866.5H11.5A1.5 1.5 0 0 0 13 12h-1a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h1V6a5 5 0 0 0-5-5" />
                        </svg>
                      </span>
                      &nbsp;&nbsp;&nbsp; Live Counselling
                    </button> */}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
{/* top header end */ }
<div className="header-next-2">
  <nav className="navbar navbar-expand-lg navbar-light  p-0">
    <div className="container">
      <button
        className="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
            <path
              stroke="rgba(255, 255, 255, 0.55)"
              stroke-linecap="round"
              stroke-miterlimit="10"
              stroke-width="2"
              d="M4 7h22M4 15h22M4 23h22"
            />
          </svg>
        </span>
      </button>
      <div
        className="collapse navbar-collapse"
        id="navbarSupportedContent"
      >
        <ul className="navbar-nav mb-lg-0">
          <li class="nav-item border-rightfornav nav-padding">
            <a
              class="nav-link active "
              aria-current="page"
              href="/"
              id="naver-link"
            >
              Home
            </a>
          </li>

          <Link to={"/about-us"}>
            <li class="nav-item plx-4 border-rightfornav nav-padding">
              <a
                class="nav-link "
                aria-current="page"
                href="/about-us"
                id="naver-link"
              >

                About Nayee Disha
              </a>
            </li>
          </Link>
          <Link to={"/we-provide"} className="d-none">
            <li class="nav-item plx-4 border-rightfornav nav-padding">
              <a
                class="nav-link "
                aria-current="page"
                href="#"
                id="naver-link"
              >
                {" "}
                We Provide{" "}
              </a>
            </li>
          </Link>
          <li className="nav-item dropdown plx-4 border-rightfornav nav-padding"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            key="h-link-si"
          >
            <div className="dropdown-wrapper">
              <a
                className="nav-link dropdown-toggle text-white"
                href="/study-abroad"
                id="navbarDropdown"
                role="button"
                aria-expanded={isHovered || isDropdownHovered ? "true" : "false"}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                Study India
              </a>
              <ul
                className={`custom-dropdown-menu ${isHovered || isDropdownHovered ? "show" : ""}`}
                id="custom-dropdown-li"
                aria-labelledby="navbarDropdown"
                onMouseEnter={handleDropdownMouseEnter}
                onMouseLeave={handleDropdownMouseLeave}
              >
               
              
                {universityindiaList.map(universityindia => (
                        <li key={`${universityindia.uid}_${universityindia.slug}`} className="dropdown-item mt-2">
                          <a href={`/${universityindia.slug}`}>
                            {universityindia.title}
                          </a>
                        </li>
                      ))}
              </ul>
            </div>
          </li>



          <ul className="navbar-nav mb-lg-0">
            <li
              className="nav-item dropdown plx-4 border-rightfornav nav-padding"
              onMouseEnter={handleDropdownToggle}
              onMouseLeave={handleDropdownClose}
            >
              <div className="dropdown-wrapper">
                <Link
                  to={
                    '/mbbs-in-abroad'
                  }


                  className="nav-link dropdown-toggle text-white"

                  id="navbarDropdown-next"
                  role="button"
                  aria-expanded={dropdownOpen ? "true" : "false"}
                >
                  MBBS In Abroad
                </Link>
                <ul
                  className={`dropdown-menu ${dropdownOpen ? "show" : ""
                    }`}
                  id="dropdown-li"
                  aria-labelledby="navbarDropdown-next"
                >
                  <li className="dropdown-item mt-2 d-flex justify-content-between">

                    <Link className="anii"
                      to={
                        '/mbbs-in-russia'
                      }
                    >
                      MBBS In Russia
                    </Link>

                    <AiFillCaretRight className="icons-nav" />
                    <ul className="submenu">
                      {russiaUniversities.map(university => (
                        <li key={university.uid} className="dropdown-item mt-2">
                          <a href={`/university/${university.slug}`}>
                            {university.name}
                          </a>
                        </li>
                      ))}

                      {/* <li className="dropdown-item mt-2">
                                <Link
                                  to={
                                    "/mbbs-in-bashkir-state-medical-university"
                                  }
                                >
                                  MBBS in Bashkir State Medical University
                                  Russia
                                </Link>
                              </li> */}

                    </ul>
                  </li>
                  <li className="dropdown-item mt-2 d-flex justify-content-between" id="Philippines">
                    <Link
                      to={
                        '/mbbs-in-nepal'
                      }
                    >
                      MBBS In Nepal


                    </Link>
                    <AiFillCaretRight className="icons-nav" />

                    <ul className="submenu">
                      {nepalUniversities.map(university => (
                        <li key={university.uid} className="dropdown-item mt-2">
                          <a href={`/university/${university.slug}`}>
                            {university.name}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </li>
                  <li className="dropdown-item mt-2 d-flex justify-content-between" id="Philippines" >

                    <Link
                      to={
                        '/mbbs-in-philippines'
                      } style={{ padding: '0px 22px 0px 0px' }}
                    >
                      MBBS In Philippines
                    </Link>

                    <AiFillCaretRight className="icons-nav" />
                    <ul className="submenu">
                      <li className="dropdown-item mt-2">
                        <Link
                          to={
                            "/mbbs-in-brokenshire-college-of-medicine-philippines"
                          }
                        >
                          MBBS in Brokenshire College of Medicine
                          Philippines
                        </Link>
                      </li>
                      <li className="dropdown-item mt-2">
                        <Link
                          to={
                            "/mbbs-in-our-lady-of-fatima-university-philippines"
                          }
                        >
                          MBBS in Our Lady of Fatima University
                          Philippines
                        </Link>
                      </li>
                      <li className="dropdown-item mt-2">
                        <Link
                          to={
                            "/mbbs-in-university-of-perpetual-help-system-dalta-philippines"
                          }
                        >
                          MBBS in University of Perpetual Help System
                          Dalta Philippines
                        </Link>
                      </li>
                      {/* Add more items as needed */}
                    </ul>

                  </li>

                  <li className="dropdown-item mt-2 d-flex justify-content-between" id="Philippines">
                    <Link className="anii"
                      to={
                        '/mbbs-in-kyrgyzstan'
                      } style={{ padding: '0px 20px 0px 0px' }}
                    >
                      MBBS In Kyrgyzstan
                    </Link>

                    <AiFillCaretRight className="icons-nav" />
                    <ul className="submenu">
                      <li className="dropdown-item mt-2">
                        <Link
                          to={
                            "/mbbs-in-osh-state-medical-university-kyrgyzstan"

                          }
                        >
                          MBBS in OSH State Medical University
                          Kyrgyzstan
                        </Link>
                      </li>
                      <li className="dropdown-item mt-2">
                        <Link
                          to={
                            "/mbbs-in-jalalabad-state-university-kyrgyzstan"
                          }
                        >
                          MBBS in Jalal-Abad State University Kyrgyzstan
                        </Link>
                      </li>

                      {/* Add more items as needed */}
                    </ul>
                  </li>
                  <li className="dropdown-item mt-2 d-flex justify-content-between" id="Philippines">

                    <Link className="anii"
                      to={
                        '/mbbs-in-china'
                      }
                    >
                      MBBS In China
                    </Link>
                    <AiFillCaretRight className="icons-nav" />
                    <ul className="submenu">
                      <li className="dropdown-item mt-2">
                        <Link
                          to={
                            "/mbbs-in-fudan-university-shanghai-medical-college-china"
                          }
                        >
                          MBBS in Peking University Health Science
                          Center China
                        </Link>
                      </li>
                      <li className="dropdown-item mt-2">
                        <Link
                          to={
                            "/mbbs-in-peking-university-health-science-center-china"
                          }
                        >
                          MBBS in Fudan University Shanghai Medical
                          College China
                        </Link>
                      </li>
                      <li className="dropdown-item mt-2">
                        <Link
                          to={
                            "/mbbs-in-zhejiang-university-school-of-medicine-china"
                          }
                        >
                          MBBS in Zhejiang University School of Medicine
                          China
                        </Link>
                      </li>
                      {/* Add more items as needed */}
                    </ul>
                  </li>

                  <li className="dropdown-item mt-2 d-flex justify-content-between" id="Philippines">

                    <Link className="anii"
                      to={
                        '/mbbs-in-bangladesh'
                      } style={{ padding: '0px 17px 0px 0px' }}
                    >
                      MBBS In Bangladesh
                    </Link>
                    <AiFillCaretRight className="icons-nav" />
                    <ul className="submenu">
                      <li className="dropdown-item mt-2">
                        <Link
                          to={
                            "/mbbs-in-dhaka-national-medical-college-bangladesh"
                          }
                        >
                          MBBS in Dhaka National Medical College
                          Bangladesh
                        </Link>
                      </li>
                      <li className="dropdown-item mt-2">
                        <Link
                          to={
                            "/mbbs-in-eastern-medical-college-bangladesh"
                          }
                        >
                          MBBS in Eastern Medical College Bangladesh
                        </Link>
                      </li>
                      <li className="dropdown-item mt-2">
                        <Link
                          to={
                            "/mbbs-in-khwaja-yunus-ali-medical-college-bangladesh"
                          }
                        >
                          MBBS in Khwaja Yunus Ali Medical College
                          Bangladesh
                        </Link>
                      </li>
                      <li className="dropdown-item mt-2">
                        <Link
                          to={
                            "/mbbs-in-holy-family-red-crescent-medical-college-bangladesh"
                          }
                        >
                          MBBS in Holy Family Red Crescent Medical
                          College Bangladesh
                        </Link>
                      </li>


                      {/* Add more items as needed */}
                    </ul>
                  </li>
                  <li className="dropdown-item mt-2 d-flex justify-content-between" id="Philippines">

                    <Link className="anii"
                      to={
                        '/mbbs-in-kazakhstan'
                      } style={{ padding: '0px 17px 0px 0px' }}
                    >
                      MBBS In Kazakhstan
                    </Link>
                    <AiFillCaretRight className="icons-nav" />
                    <ul className="submenu">
                      <li className="dropdown-item mt-2">
                        <Link
                          to={
                            "/mbbs-in-south-kazakhstan-medical-academy-kazakhstan"
                          }
                        >
                          MBBS in South Kazakhstan Medical Academy
                          Kazakhstan
                        </Link>
                      </li>
                      <li className="dropdown-item mt-2">
                        <Link
                          to={
                            "/mbbs-in-kazakh-national-medical-university-kazakhstan"
                          }
                        >
                          MBBS in Kazakh National Medical University
                          Kazakhstan
                        </Link>
                      </li>


                      <li className="dropdown-item mt-2">
                        <Link
                          to={
                            "/mbbs-in-kokshetau-state-university-kazakhstan"
                          }
                        >
                          MBBS in Kokshetau State University Kazakhstan
                        </Link>
                      </li>

                      {/* Add more items as needed */}
                    </ul>
                  </li>

                  
                  {universityList
                    .filter(
                      (university) =>
                        !excludedTitles.some(
                          (title) => title.toLowerCase() === university.title.toLowerCase()
                        )
                    )
                    .map((university) => (
                      <li key={university.id} className="dropdown-item mt-2">
                        <Link style={{ textTransform: "capitalize" }}
                          to={`/${university.slug}`}>{university.title}</Link>
                        {/* <a style={{ textTransform: "capitalize" }} href={`/${university.slug}`}>{university.title}</a> */}
                      </li>
                    ))}
                </ul>
              </div>
            </li>
            {/* Other menu items */}
            {/* ... */}
          </ul>
          {/* <li
className="nav-item dropdown plx-4 border-rightfornav nav-padding"
onMouseEnter={handleNewMouseEnter}
onMouseLeave={handleNewMouseLeave}
>
<div className="dropdown-wrapper">
  <a
            className="nav-link dropdown-toggle text-white"
    href="#"
    id="navbarDropdown"
    role="button"
    aria-expanded={newIsHovered || newIsDropdownHovered ? "true" : "false"}
    onMouseEnter={handleNewMouseEnter}
    onMouseLeave={handleNewMouseLeave}
  >
    Courses
  </a>
  <ul
    className={`custom-dropdown-menu ${newIsHovered || newIsDropdownHovered ? "show" : ""}`}
    id="custom-dropdown-li"
    aria-labelledby="newNavbarDropdown"
    onMouseEnter={handleNewDropdownMouseEnter}
    onMouseLeave={handleNewDropdownMouseLeave}
  >
     <li className="dropdown-item course mt-2" id="dropdown-india">
     <a href=""
          className="btn-link"
          onClick={() => handleCourseClick('MBBS')}
          style={{ background: 'none', border: 'none', padding: 0, margin: 0, color: 'inherit', cursor: 'pointer' }}
        >MBBS</a>
    </li>
    <li className="dropdown-item course demo mt-2">
      <a href="engineering-abroad">BDS</a>
    </li>
  </ul>
</div>
</li> */}
          <li class="nav-item plx-4 border-rightfornav nav-padding">
            <a
              class="nav-link active"
              aria-current="page"
              href="/blogs"
              id="naver-link"
            >
              Blogs
            </a>
          </li>


          <li class="nav-item plx-4 border-rightfornav nav-padding">
            <Link to={"/contact-us"}>
              <a
                class="nav-link active"
                aria-current="page"
                href="#"
                id="naver-link"
              >
                Contact Us
              </a>
            </Link>
          </li>

          <li class="nav-item plx-4 border-rightfornav nav-padding d-none">

            <a
              class="nav-link active"
              aria-current="page"
              href="#"
              data-bs-toggle="modal"
              data-bs-target="#exampleModalsearch"
            >
              <FaSearch />
            </a>

          </li>
        </ul>
      </div>
    </div>
  </nav>
</div>
      </div >
    );
  };
return (
  <nav className="Desktop_view">
    <Link to="/" className="link desktop_none">
      <img src={nayeedishalogosfin} loading="lazy" alt="logo"></img>

    </Link>
    {!isMobile ? (
      desktopmenu()
    ) : (
      <>
        {/*  */}
        <div className=" d-flex mds ">
          <div className="divider-crddesk">
            <span className="invisible"></span>
            {/* <div className="d-flex"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                data-bs-whatever="@mdo"
              >


                <img src={ApplyNow} loading="lazy" alt="Apply Online" />

                &nbsp;

              </div> */}
          </div>
          &nbsp;&nbsp;
          <div className="  ">
            <div className="pt-1 ph svgrs">
              <a href="tel:+91 8860031900">   
                <svg
                xmlns="http://www.w3.org/2000/svg"
                width="23"
                height="29"
                fill="currentColor"
                className="bi bi-telephone"
                viewBox="0 0 16 16"
              >
                <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
              </svg>
              </a>
              &nbsp;
            </div>
          </div>
          &nbsp; &nbsp;|&nbsp;&nbsp;&nbsp;
          <div className=" ">
            <div className="pt-1 wh svgrs2">
              <a href="https://wa.me/+918860031900">  
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="23"
                height="29"
                fill="currentColor"
                className="bi bi-whatsapp"
                viewBox="0 0 16 16"
              >
                <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" />
              </svg>
              </a>
              &nbsp;
            </div>
          </div>
        </div>
        {/*  */}
        <div className="icons">
          <div
            className="mobile-menu-icon"
            onClick={() => handleToggleSidebar(true)}
          >
            <FaBars className="fa_fabards" />

          </div>
        </div>




      </>
    )}
  </nav>
);
};



export default Header;
