import { configureStore } from '@reduxjs/toolkit';
import universityReducer from './universitySlice'; // Adjust the path as needed
import blogReducer from './blogReducer';
import universityDetailsReducer from './universityDetailsSlice';
import navigationDataReducer from './navigationDataSlice';
import universityfilterReducer from './universityReducer';
import courseReducer from './reducers/courseReducer';
// import blogReducer from './blogSlice';
const store = configureStore({
    reducer: {
        university: universityReducer,
        blog: blogReducer,
        universityDetails: universityDetailsReducer,
        navigationData: navigationDataReducer,
        filteruniversity: universityReducer,
        courses: courseReducer,
    },
});

export default store;
