import { React, useEffect, useState} from "react";

import { Bs1CircleFill, Bs2CircleFill, Bs3CircleFill, Bs4CircleFill, Bs5CircleFill, Bs6CircleFill, Bs7CircleFill, Bs8CircleFill, Bs9CircleFill } from "react-icons/bs";
import Admissionimg from '../../img/admission.svg';
import TabAdmissionimg from '../../img/tabmobile.svg';
import { API_URL } from '../../setting/apiConfig';

const KokshetauKazakhstan = () => {
  const options = {
    loop: true,
    margin: 10,
    items: 1,
    autoplay: true,
    responsive: {
      0: {
        items: 1,
        nav: true,

      },
      600: {
        items: 3,
      },
      1000: {
        items: 4,
      },
    },
  };
  const [universities, setUniversities] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    fetch(`${API_URL}university-details`)
      .then((response) => response.json())
      .then((data) => {
        // Assuming 'results' contains the array of universities
        setUniversities(data);
        setIsLoading(false);

      })
      .catch((error) => {
        setIsLoading(false);
        console.error('Error fetching data:', error);
      });
  }, []);
  return (
    <>



      {/* section1 */}

      <div className='abt-sectionn pt-5 pb-5'>


        <div className='container'>

          <h1>MBBS in Kokshetau State University, Kazakhstan</h1>

          <div className='bds'></div>
          <div id='brdrs'></div>

          {/* <p>It is a long established fact that a reader.</p> */}


        </div>


      </div>




      <div className='container abt-2 mt-5 mb-5'>


        <p className='mb-4'>Welcome to Nayee Disha’s dedicated page on Kokshetau State University in Kazakhstan. As a trusted educational consultant assisting Indian students in pursuing their dreams of studying MBBS abroad, we are delighted to present Kokshetau State University, a reputable institution located in the heart of Kazakhstan. With a strong focus on providing quality education and fostering a supportive learning environment, Kokshetau State University offers an exceptional opportunity for Indian students to embark on a fulfilling journey towards a medical career. This page serves as a gateway to explore the outstanding academic offerings, renowned faculty, and vibrant campus life that make Kokshetau State University an excellent choice for Indian students seeking a comprehensive and internationally recognized MBBS program.</p>

        <hr></hr>
        <div className='py-3 rounded'>

          <div className='container mt-3'>
            <h3 className='border_before'>Advantages of MBBS in Kokshetau State University, Kazakhstan</h3>
            <div className='row'>
              <div className='col-md-3 mt-4 '>
                <div className='bg-coloringrideant card-height  p-3 rounded'>
                  <b className='f-19'>Global Recognition</b>
                  <p className='f-17'>Acclaimed Degrees Opening the International Doors and Precious Chances</p>
                </div>

              </div>
              <div className='col-md-3 mt-4 '>
                <div className='bg-coloringrideant card-height p-3 rounded'>
                  <b className='f-19'>Affordable Education</b>
                  <p className='f-17'>Cost-Effective MBBS Programs without Compromising Quality and Rigour</p>
                </div>

              </div>
              <div className='col-md-3 mt-4 '>
                <div className='bg-coloringrideant card-height p-3 rounded'>
                  <b className='f-19'>Experienced Faculty</b>
                  <p className='f-17'>Renowned Professors Ensuring Comprehensive Medical Education Is Provided</p>
                </div>

              </div>
              <div className='col-md-3 mt-4 '>
                <div className='bg-coloringrideant card-height p-3 rounded'>
                  <b className='f-19'>Cultural Diversity</b>
                  <p className='f-17'>Exposure to Multicultural Environment Enhancing Global Perspective and Exposure</p>
                </div>

              </div>
            </div>
          </div>

          <div className='container mb-4 mt-3'>
            <div className='row'>
              <div className='col-md-3 mt-4 '>
                <div className='bg-coloringrideant card-height p-3 rounded'>



                  <b className='f-19'>Advanced Infrastructure</b>
                  <p className='f-17'>State-of-the-Art Facilities Are Provided Cutting-Edge Learning Opportunities</p>
                </div>

              </div>
              <div className='col-md-3 mt-4 '>
                <div className='bg-coloringrideant card-height p-3 rounded'>
                  <b className='f-19'>High-Quality Teaching</b>
                  <p className='f-17'>Rigorous Curriculum and Practical Training for Competent Medical Professionals</p>
                </div>

              </div>
              <div className='col-md-3 mt-4 '>
                <div className='bg-coloringrideant card-height p-3 rounded'>
                  <b className='f-19'>English-Medium</b>
                  <p className='f-17'>Language Barrier-Free Education Facilitating Easy Learning and Communication</p>
                </div>

              </div>
              <div className='col-md-3 mt-4 '>
                <div className='bg-coloringrideant card-height p-3 rounded'>
                  <b className='f-19'>Career Opportunities</b>
                  <p className='f-17'>Seamless Pathway to Explore Employment Options in Russia and Beyond.</p>
                </div>

              </div>
            </div>
          </div>
        </div>



        <hr></hr>




      </div>
      <div className='container'>
        <h3 className='border_before'>Facts about Kokshetau State University, Kazakhstan</h3>
        <div className='row'>
          <div className='col-md-12'>
            <ul className='list-none mt-2'>
              <li className='mt-1 '><Bs1CircleFill />&nbsp; Established Academic Institution: Kokshetau State University is a well-established and reputable academic institution in Kazakhstan with a rich history of providing quality education.</li>
              <li className='mt-2 '> <Bs2CircleFill /> &nbsp; Range of Programs: The university offers a wide range of academic programs, including a comprehensive MBBS program, catering to the diverse educational needs of students.</li>
              <li className='mt-2 '> <Bs3CircleFill />&nbsp; Internationally Recognized Degrees: The degrees awarded by Kokshetau State University are internationally recognized, ensuring that graduates have opportunities for global career prospects.</li>
              <li className='mt-2 '> <Bs4CircleFill /> &nbsp; Experienced Faculty: The university boasts a dedicated faculty comprising experienced professors and experts in their respective fields, ensuring high-quality education and mentorship for students.</li>
              <li className='mt-2 '> <Bs5CircleFill /> &nbsp; State-of-the-Art Facilities: Kokshetau State University is equipped with modern facilities, well-equipped laboratories, libraries, and research centers, providing students with a conducive learning environment.</li>
              <li className='mt-2 '> <Bs6CircleFill /> &nbsp; Multicultural Campus: The university embraces diversity and welcomes students from various cultural backgrounds, fostering a multicultural campus environment that promotes cross-cultural understanding and collaboration.</li>
              <li className='mt-2 '><Bs7CircleFill /> &nbsp; Practical Training Opportunities: The MBBS program at Kokshetau State University emphasizes practical training through clinical rotations in affiliated hospitals, allowing students to gain valuable hands-on experience.</li>
              <li className='mt-2 '><Bs8CircleFill /> &nbsp; Research and Innovation: The university actively promotes research and innovation, providing opportunities for students to engage in research projects and contribute to advancements in medical knowledge.</li>
              <li className='mt-2 '><Bs9CircleFill /> &nbsp; Affordable Tuition Fees: Kokshetau State University offers competitive tuition fees compared to many other international universities, making it an attractive choice for Indian students seeking an affordable MBBS program.</li>

            </ul>
          </div>

        </div>
      </div>
      <hr></hr>
      <div className='container'>
        <h3 className='border_before'>Admission Process and Documents Needed</h3>
        <div className='row'>
          <div className='col-md-12'>

          </div>

        </div>
      </div>

      <div className='container'>

      <picture data-bs-toggle="modal"
data-bs-target="#exampleModal"
data-bs-whatever="@mdo">
{/* Mobile Image */}
<source media="(max-width: 576px)" loading="lazy" srcSet={TabAdmissionimg} />
{/* Tablet Image */}
<source media="(max-width: 992px)" loading="lazy" srcSet={TabAdmissionimg} />
{/* Desktop Image */}
<img
  className='w-100 mt-3'
   src={Admissionimg} // Fallback image for larger screens
   alt="Responsive slide"
   loading="lazy"
/>
</picture>
      </div>

      <br></br>
   
    </>
  );
};

export default KokshetauKazakhstan;
