import { createAsyncThunk } from '@reduxjs/toolkit';
import { API_URL } from '../setting/apiConfig';

export const fetchUniversityDetails = createAsyncThunk(
    'university/fetchDetails',
    async (uid, { getState }) => {
        const state = getState().university;
        const cachedData = state[uid];

        if (cachedData) {
            return cachedData; // Return cached data if available
        }

        // Fetch data from API
        const response = await fetch(`${API_URL}university-details/${uid}`);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const fetchedData = await response.json();
        return { uid, data: fetchedData }; // Return fetched data
    }
);
