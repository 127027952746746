import { React, useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { IoIosCloseCircle } from "react-icons/io";
import HorizontalLinearStepper from '../pages/registration-steper/horizontalLinearStepper'
import { Button } from 'react-bootstrap';
import { API_URL } from '../setting/apiConfig';
import whatsapp from '../img/whatsapp.svg'
import { FaPhone } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import Skeleton from 'react-loading-skeleton'; // If using react-loading-skeleton
import 'react-loading-skeleton/dist/skeleton.css';
import { fetchActiveData } from '../store/navigationDataSlice';
import { useDispatch, useSelector } from 'react-redux';
import SearchBox from './search_box';


const Footer = () => {
  const handleAccept = () => {
    console.log('User accepted cookies.');
  };

  const dispatch = useDispatch();
  const { list, loadingg, error } = useSelector((state) => state.navigationData);

  console.log("___________list__________", list)

  useEffect(() => {
    
    // Initialize Bootstrap tooltip
    const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-toggle="tooltip"]'))
    const tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new window.bootstrap.Tooltip(tooltipTriggerEl)
    })
  }, []);


  const handleDecline = () => {
    console.log('User declined cookies.');
  };



  const [universities, setUniversities] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [value, setValue] = useState(0);
  const [nepalUniversities, setNepalUniversities] = useState([]);
  const [indiaUniversities, setIndiaUniversities] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [cache, setCache] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);

        const universityResponse = await fetch(API_URL+ 'university-details');
        const universityData = await universityResponse.json();

        // Filter universities for Russia and Nepal
        const russiaUniversities = universityData.filter(university => university.country === 'Russia');
        const nepalUnis = universityData.filter(university => university.country === 'Nepal');
        const indiaUnis = universityData.filter(university => university.country === 'India');

        setUniversities(russiaUniversities);
        setNepalUniversities(nepalUnis);
        setIndiaUniversities(indiaUnis);


        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);



  const onSelect = (record) => {
    window.location.href = record.url;

  };
  const renderOption = (record, index) => {
    return (
      <div key={`${record.value}${index}`} onClick={() => onSelect(record)}>
        <span>{record.value}</span>
        <span style={{ marginLeft: '5px', color: '#999' }}>{record.url}</span>
      </div>
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${API_URL}global-search/${query}`
        );
        const result = await response.json();
        const transformedData = result[0]?.data.map((item) => ({
          key: item.logo,
          value: item.name,
          url: item.slug,
        }));

        // Update search data and cache with new results
        setSearchData(transformedData);
        setCache((prevCache) => ({
          ...prevCache,
          [query]: transformedData,
        }));
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    const handleSearch = () => {
      if (query.length >= 3) {
        if (cache[query]) {
          // If query is in cache, use it directly
          setSearchData(cache[query]);
        } else {
          // Otherwise, fetch data from the API
          setLoading(true);
          fetchData();
        }
      } else {
        // Clear results if query length < 3
        setSearchData([]);
      }
    };

    // Debounce logic
    const debounce = setTimeout(() => {
      handleSearch();
    }, 300); // Increased debounce for better stability

    return () => clearTimeout(debounce); // Cleanup on query change
  }, [query, cache]);


  useEffect(() => {
    dispatch(fetchActiveData()); // Fetch active universities on component mount
  }, []);

  if (loading) return (
    <div>
      <Skeleton count={5} height={10} />
      <Skeleton count={3} height={10} />
    </div>
  );


  if (error) {
    return <p>Error: {error}</p>;
  }
  if (list.length === 0) {
    return <div>
      <Skeleton count={5} height={10} />
      <Skeleton count={3} height={10} />
    </div>;
  }
  

  return (
    <>
      <div
        className="modal fade py-0"
        id="exampleModalsearch"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-fullscreen" style={{ margin: '0' }}>
          <div className="modal-content" style={{ height: '100vh', borderRadius: '0' }}>
            <div className="modal-header">
              <p className="h5 modal-title" id="exampleModalLabel">Start Searching...</p>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div>
                {/* <SearchBox searchData={searchData} /> */}
              
                {loading && (
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                )}
              </div>
              <p className="h4 p-2">TOP SEARCHES</p>
            </div>
          </div>
        </div>
      </div>




      <div className='footer1 p-5'>
        <div className='container'>
          <p style={{ fontWeight: 800, fontSize: 20 }} className='h5 mt-2 mb-4 text-white study_abroad '>Study Abroad Option</p>


          <div className='row mt-2'>
            {list.map((footerdata) => (

              <div className='col-md-3 col-6' key={footerdata.id} ><Link to={{ "pathname": `/${footerdata.slug}` }}><p>{footerdata.title || 'No Title'}</p></Link></div>
            ))}
          </div>



        </div>




      </div>



      <div className="footer3 ">

        <div className='container'>

          <div className='row'>

            <div className='col-md-3 col-12'>

              <p style={{ fontWeight: 800, fontSize: 20 }} className='mt-2 h5'>Our Office Address</p>
              <div className='pt-2 foot-res2'>
                <p><b>Head office -</b> Suite B-1405, B-1407, 14th Floor,T3, Techzone-4, NX One, Greater Noida West, Uttar Pradesh 201306</p>
                <a href="tel:+91 8860031900"> <p><FaPhone /> +91 8860031900</p></a>

                <a href="mailto:info@nayeedisha.in">   <p><MdEmail /> info@nayeedisha.in</p></a>



              </div>

            </div>


            <div className='col-md-3 pt-5 col-12'>

 
              <div className='pt-1 foot-res2 mt-2'>
                <p><b>Regional Office - </b>
                  NayeeDisha Education, B-361, Sidharth Enclave, Taramandal,
                  Near GDA office, Gorakhpur, Uttar Pradesh 273001</p>

                <a href="tel:+91 8860031900"> <p><FaPhone /> +91 8860031900</p></a>

                <a href="mailto:info@nayeedisha.in">   <p><MdEmail /> info@nayeedisha.in</p></a>
              </div>

            </div>



            {/* <div className='col pt-2'>

              <h5>Tools & Research</h5>
              <div className='pt-2'>
                <p>Career Compass</p>

                <p>Write a review</p>

                <p>QnA Forum</p>

                <p>Predictor</p>

                <p>Unit Calculator</p>

              </div>

            </div> */}


            <div className='col-md-3 col-6 pt-2'>

              <p style={{ fontWeight: 800, fontSize: 20 }} className='h5'>Quick Links</p>
              <div className='pt-2'>
                <Link to={"/about-us"} ><p>About Us</p></Link>

                <Link to={"/contact-us"} ><p>Contact Us</p></Link>

                <Link to={"/blogs"} ><p>Latest Blogs</p></Link>
                <Link to={"/we-provide"} ><p>We Provide</p></Link>
              </div>

            </div>


            <div className='col-md-3 col-6 pt-2'>

              <p style={{ fontWeight: 800, fontSize: 20 }}>Follow</p>
              <div className='pt-2'>
                <a href='https://www.facebook.com/people/NayeeDisha-Study-Abroad/61559962673504/' target="_blank" rel="noopener"><p>Facebook</p></a>

                <a href='https://x.com/NayeeInfo' target="_blank" rel="noopener"><p>Twitter</p></a>

                <a href='https://www.instagram.com/nayeedisha_official/?hl=en' target="_blank" rel="noopener"> <p>Instagram</p></a>

                <a href='https://www.linkedin.com/company/nayeedisha/' target="_blank" rel="noopener"><p>Linkedin</p></a>
                <a href='https://www.youtube.com/@nayeedisha' target="_blank" rel="noopener"><p>YouTube</p></a>



              </div>
            </div>



          </div>

        </div>


      </div>










      {/* modal */}

      <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">

        <div className=" modal-dialog modal-xl">
          <Button variant="secondary closebutton"><IoIosCloseCircle className='close_icon_width' /></Button>
          <div className="modal-content">
            <HorizontalLinearStepper />
          </div>

        </div>
      </div>



      <div className='text-white py-3 footer3'>
        <div className='container'>
          <div className='row'>

            <div className='col-lg-7 col-md-12'>
              <div className='footer_Privacy d-flex '>
                <a href='/privacy-policy'> <p>Privacy Policy</p> </a>
                &nbsp; |   &nbsp;
                <a href='/terms-and-conditions'><p>Terms and Conditions</p></a>
                {/* &nbsp;  <span className='mobilesection-hide_new'>|</span>  &nbsp;
                <a href='tel:+91 8860031900'><p>Contact our education counsellors  : +91 8860031900</p></a> */}
              </div>

            </div>
            <div className='col-lg-5 col-md-12 text-center footerbottom'>
              <p>Copyright © 2025 All Rights Reserved.</p>
            </div>
          </div>
        </div>


      </div>



      {/* end modal */}








      {/* SECOND MODAL */}
      {/* modal start */}



      {/* 


      {/* modal end */}


      <div id="fixed_quick_enquiry">
        <p className="callvideo"><a href='' role="button" className="btn btn-primary btn-lg rrighbtn" data-bs-toggle="modal"
          data-bs-target="#exampleModal"
          data-bs-whatever="@mdo">Admissions 25-26 </a></p>
      </div>

      {/*  */}

      <a href="https://wa.me/+918860031900" className='float' target="_blank" rel="noopener">
        <img src={whatsapp} className="whatspp" loading="lazy" alt='Nayeedisha' />
      </a>

      <div className="bottom-nav text-white h4 wave_talk" data-bs-toggle="modal"
        data-bs-target="#exampleModal"
        data-bs-whatever="@mdo">

        Admission Open <span className="year"> &nbsp; 25-26</span>

      </div>



    </>
  );
};

export default Footer;
