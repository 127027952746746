import React, { useEffect, useState } from "react";
import { useComparison } from '../compare_colleges/CompareContext/CompareContext';
import { IoGitCompareOutline } from "react-icons/io5";
import { IoIosArrowRoundForward } from "react-icons/io";
import { FaTimes } from 'react-icons/fa';
import { GoPlusCircle } from "react-icons/go";
import { useHistory } from "react-router-dom";
import { Badge } from "react-bootstrap";
import { API_URL } from "../../setting/apiConfig";

const Compare = () => {
    const history = useHistory();
    const [colleges, setColleges] = useState([]);
    const [showDropdown, setShowDropdown] = useState([false, false, false]);
    const [selectedCollege, setSelectedCollege] = useState([null, null, null]);
    const [searchQuery, setSearchQuery] = useState(['', '', '']);
    const { setComparisonData } = useComparison();

    useEffect(() => {
        fetch(`${API_URL}university-list`)
            .then(response => response.json())
            .then(data => {
                setColleges(data);
               
                // Pre-select two random universities for the first two slots
                if (data.length > 1) {
                    const randomIndex1 = Math.floor(Math.random() * data.length);
                    let randomIndex2 = Math.floor(Math.random() * data.length);
    
                    // Ensure the two random indices are not the same
                    while (randomIndex2 === randomIndex1) {
                        randomIndex2 = Math.floor(Math.random() * data.length);
                    }
    
                    const selectedUniversities = [data[randomIndex1], data[randomIndex2], null];
                    setSelectedCollege(selectedUniversities);
                }
            })
            .catch(error => console.error('Error fetching data:', error));
    }, []);
    
    const handleAddCollegeClick = (index) => {
        const newShowDropdown = showDropdown.map((value, idx) => (idx === index ? !value : value));
        setShowDropdown(newShowDropdown);
    };

    const handleCollegeChange = (index, college) => {
        if (selectedCollege.includes(college)) {
            alert('This University has already been selected.');
            return;
        }
        const newSelectedCollege = selectedCollege.map((value, idx) => (idx === index ? college : value));
        setSelectedCollege(newSelectedCollege);
        const newShowDropdown = showDropdown.map((value, idx) => (idx === index ? false : value));
        setShowDropdown(newShowDropdown);
        setSearchQuery(searchQuery.map((value, idx) => (idx === index ? '' : value)));
    };

    const handleRemoveCollege = (index) => {
        const newShowDropdown = showDropdown.map((value, idx) => (idx === index ? false : value));
        const newSelectedCollege = selectedCollege.map((value, idx) => (idx === index ? null : value));
        setShowDropdown(newShowDropdown);
        setSelectedCollege(newSelectedCollege);
    };

    const handleSearchChange = (index, query) => {
        const newSearchQuery = searchQuery.map((value, idx) => (idx === index ? query : value));
        setSearchQuery(newSearchQuery);
    };

    const filteredColleges = (index) => {
        return colleges.filter(college =>
            college.name.toLowerCase().includes(searchQuery[index].toLowerCase())
        );
    };

    const handleCompareNow = () => {
        const selectedColleges = selectedCollege.filter(college => college);
        if (selectedColleges.length < 2) {
            alert('Please select at least two colleges for comparison.');
            return;
        }

        const selectedUids = selectedColleges.map(college => college.uid);
        const collegeNames = selectedColleges.map(college => college.name).join('-vs-');

        const data = {
            university_ids: selectedUids
        };

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        };

        fetch(`${API_URL}compare-universities`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log('API Response:', result);

                // Save the comparison data to local storage
                localStorage.setItem('comparisonData', JSON.stringify(result));

                // Set the comparison data in context
                setComparisonData(result);

                // Construct the URL based on college names
                const urlSlug = collegeNames.toLowerCase().replace(/\s+/g, '-').replace(/[^a-zA-Z0-9-]/g, '');
                history.push(`/compare/${urlSlug}`);
            })
            .catch(error => {
                console.error('Error comparing universities:', error);
            });
    };

    return (
        <section className="compare_colleges" id="compare">
            <div className="container mt-4">
                <div className="text-center mt-4">
                    <h3></h3>
                    
                    <h1 className="h2 fw-bold my-3 text-center">
                    Compare
                      
                     <span className="text-primary"> Colleges</span>
                  </h1>
                    

                  
                </div>
                <div className="row mt-4">
                <p className="text-center mt-2" id="my-p">
                    Choosing the right college is a crucial decision that can significantly impact your future. With numerous colleges offering diverse courses and facilities, comparing them helps you identify the best fit for your academic and personal goals. Compare your top university choices using our free tool given below.
                    </p>
                    {selectedCollege.map((college, index) => (
                        <div className="col-lg-4 col-md-6 mt-4" key={index}>
                            <div className="compare ">
                                {college ? (
                                    
                                    <div className="card" id="icons-crd" style={{ width: '100%' }}>
                                    
                                        <button className="compare_closebutton" onClick={() => handleRemoveCollege(index)}>
                                            <FaTimes />
                                        </button>
                                        <div
                                            className="university-section "
                                            style={{
                                                width: '100%',
                                                backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${college.cover_image})`,
                                                backgroundRepeat: 'no-repeat',
                                                backgroundSize: '100% 186%',
                                                height: '150px',
                                                backgroundPosition: 'center'
                                            }}
                                        >

                                        </div>
                                        <div className="card-body comparetext">
                                            <img src={college.logo} className="" loading="lazy" alt="Nayeedisha" />
                                            <h5 className="mt-2 f-16 text-transform" style={{fontWeight:700}}>{college.name}</h5>
                                            <div>
                                            {college.Courses && college.Courses.length > 0 ? (
                                                               college.Courses.map((Courses, index) => (
                                                                  <div style={{fontWeight:700,color:"#ca1442"}} key={index}>

                                                                     {/* <p className='para1' style={{ color: '#000' }}>Fee <span className='ugc' style={{ color: '#000' }}>&nbsp;&nbsp;&nbsp;: {budget.course_name ? budget.course_name : 'Not found'} </span></p> */}

                                                                      {Courses.name ? Courses.name : 'Not found'} 

                                                                  </div>
                                                               ))
                                                            ) : (
                                                               <p>Not available</p>
                                                            )}
                                                <div className="pt-1 d-flex justify-content-between">
                                                <p className=" d-flex align-items-center" style={{ color: '#000',fontSize:'16' }}>
                                                        Duration :&nbsp;
                                                        <span className="ugc" style={{ color: '#000' }}>
                                                        {college.Courses && college.Courses.length > 0 ? (
                                                               college.Courses.map((Courses, index) => (
                                                                  <div style={{fontWeight:700,color:"#000"}} key={index}>

                                                                     {/* <p className='para1' style={{ color: '#000' }}>Fee <span className='ugc' style={{ color: '#000' }}>&nbsp;&nbsp;&nbsp;: {budget.course_name ? budget.course_name : 'Not found'} </span></p> */}

                                                                      {Courses.duration ? Courses.duration : 'Not found'} 

                                                                  </div>
                                                               ))
                                                            ) : (
                                                               <p>Not available</p>
                                                            )}
                                                        </span>
                                                    </p>
                                                    <p className="  " style={{ color: '#000',fontSize:'16' }}>
                                                        Country :&nbsp;
                                                        <span className="ugc" style={{ color: '#000' }}>
                                                            {college.country ? college.country : 'Not found'}
                                                        </span>
                                                    </p>

                                                </div>
                                                <br></br>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        <div className="text-center" >
                                            <GoPlusCircle className="plusicon" onClick={() => handleAddCollegeClick(index)} />
                                            <p onClick={() => handleAddCollegeClick(index)}>Add College</p>
                                            {showDropdown[index] && (
                                                <div className="custom-dropdown">
                                                    <input
                                                        type="text"
                                                        value={searchQuery[index]}
                                                        onChange={(e) => handleSearchChange(index, e.target.value)}
                                                        placeholder="Search college by name"
                                                        className="dropdown-input input_compair"
                                                        disabled={index > 0 && !selectedCollege[index - 1]}
                                                    />
                                                    <div className="dropdown-list compare_dropdown">
                                                        {filteredColleges(index).map(college => (
                                                            <div
                                                                key={college.uid}
                                                                className="dropdown-item compare_itemlist"
                                                                onClick={() => handleCollegeChange(index, college)}
                                                            >
                                                                {college.name}
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
                <br />
              
               <div className="row justify-content-center mt-5">
                  <div className="col-md-3 col-12 text-center">
                     <button  disabled={!selectedCollege[0]}
                  onClick={handleCompareNow} className="btn btn-outline-primary" >
                     Compare Now &nbsp; <IoIosArrowRoundForward />
                     </button>
                  </div>
               </div>
            </div>
          
           
        </section>
     
    );
}

export default Compare;
